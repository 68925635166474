<template>
  <div class="ramadan">
    <div class="peskil">
      <!-- <PesantrenKilat :articles="$store.state.articles.articles_tags.items" /> -->
    </div>
    <section class="banner">
      <img
        src="/storage/images/ramadan/bg-desktop.webp"
        class="banner-ramadan is-desktop"
        alt=""
      />
      <img
        src="/storage/images/ramadan/bg-mobile.png"
        class="banner-ramadan is-mobile"
        alt=""
      />
      <div class="headline">
        <div class="container">
          <div class="title-wrapper">
            <h2 class="title">Artikel Ramadan</h2>
          </div>
        </div>
      </div>
    </section>
    <!-- START ADS -->
    <ReligiHorizontalAds />
    <!-- ADS END -->
    <section class="artikel">
      <div class="container">
        <div class="box-artikel">
          <div class="list-artikel">
            <Link
              v-for="item in tags.items"
              :key="item.id"
              :to="{
                name: item.isText ? 'read-channel-slug' : 'video-channel-slug',
                params: {
                  channel: item.channel.slug,
                  slug: item.slug,
                },
                query: {
                  ref: 'artikel-ramadan',
                },
              }"
              class="box-wrapper"
            >
              <ImageResponsive
                :imageUrl="
                  item.thumbnail && item.thumbnail.medium
                    ? item.thumbnail.medium
                    : 'https://stg.narasi.co.id/storage/images/dummy.png'
                "
                :fromUrl="true"
                width="324"
                height="181"
                widthMobile="100"
                heightMobile="100"
                class="card-narasi-ramadan-image"
              />
              <div class="copy-wrapper">
                <div class="card-narasi-ramadan-channel-category">
                  <Link
                    v-if="item && item.channel"
                    :to="{
                      name: 'program-childBrand-slugProgram',
                      params: {
                        childBrand: item.channel.program.slug,
                        slugProgram: item.channel.slug,
                      },
                      query: {
                        type: 'highlights',
                      },
                    }"
                    class="channel"
                    >{{ item.channel.title }}</Link
                  >
                  <!-- <span v-show="item.category ? item.category.slug : ''"
                    >&bull;</span
                  >
                  <Link
                    v-show="item.category ? item.category.slug : ''"
                    :to="{
                      name: 'interest',
                      query: {
                        type: item.category ? item.category.slug : '',
                      },
                    }"
                    class="category"
                    >{{ item.category ? item.category.title : "" }}</Link
                  > -->
                </div>
                <div class="card-narasi-ramadan-title">
                  {{ item.title }}
                </div>
              </div>
            </Link>
          </div>

          <div
            class="pagination-cards"
            v-if="tags.items.length > 0 && tags.pages > 1"
          >
            <paginate
              v-if="tags && tags.pages > 1"
              :page-count="tags.pages"
              :page-range="3"
              :margin-pages="1"
              prev-class="arrow"
              next-class="arrow"
              :click-handler="clickCallback"
              :container-class="'pagination'"
              :page-class="'page-item'"
              v-model="page"
            >
              <template slot="prevContent">
                <Icons name="arrow-left" color="#757575" />
              </template>
              <template slot="nextContent">
                <Icons name="arrow-right" color="#757575" />
              </template>
            </paginate>
          </div>
        </div>
        <!-- ADS PROPS START -->
        <div id="M857969ScriptRootC1408001" class="ads-props"></div>
        <!-- ADS PROPS END -->
      </div>
    </section>
  </div>
</template>

<script>
import config from "../../../config";
import { mapGetters, mapState } from "vuex";
import Vue from "vue";
import Paginate from "/node_modules/vuejs-paginate/src/components/Paginate";
import Link from "@/components/Link";
import ImageResponsive from "@/components/ImageResponsive";
import PesantrenKilat from "@/components/ramadan/PesantrenKilat.vue";
import ReligiHorizontalAds from "@/components/ads/religi/Horizontal.vue";

Vue.component("paginate", Paginate);

export default {
  components: {
    ImageResponsive,
    Link,
    PesantrenKilat,
    ReligiHorizontalAds,
  },
  async asyncData({ error, store, route, app, redirect }) {
    try {
      store.commit("seo/SET_SEO", {
        title: `Jadwal Sholat & Imsakiyah Hari Ini DKI Jakarta dan Sekitarnya ${app
          .$moment()
          .local()
          .format("MMMM")} ${app.$moment().local().format("YYYY")}`,
        desc: `Jadwal Sholat, Imsakiyah, Buka Puasa dan Sholat Subuh, Dzuhur, Ashar, Magrib, Isya DKI Jakarta dan Sekitarnya ${app
          .$moment()
          .local()
          .format("MMMM")} ${app
          .$moment()
          .local()
          .format("YYYY")} Terbaru dan Terlengkap Hanya di Narasi.`,
        image: "https://narasi.tv/icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  data() {
    return {
      page: this.$route.query.page ? this.$route.query.page : 1,
    };
  },
  watch: {},
  head() {
    return {
      ...this.headReturn,
      script: [{ src: "https://jsc.mgid.com/p/r/props.narasi.tv.1408001.js" }],
    };
  },
  watch: {
    "$route.query.page": {
      handler: function (page) {
        if (page) {
          let page_ = parseInt(page);
          this.page = page_;
          this.$store.dispatch("videos/getTags", {
            tagsSlug: "ramadan-dan-religi",
            page: this.page,
            limit: 9,
            firstLoad: true,
          });
        } else {
          this.page = 1;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      tags: (state) => {
        return state.videos.tags;
      },
    }),
  },
  methods: {
    clickCallback(page) {
      this.page = page;
      this.$router.replace({
        name: "religi-artikel",
        query: {
          page: page,
        },
      });
    },
  },
  async fetch() {
    await this.$store.dispatch("videos/getTags", {
      tagsSlug: "ramadan-dan-religi",
      page: this.page,
      limit: 9,
      firstLoad: true,
    });
  },
  mounted() {
    this.$store.dispatch("articles/getArticlesByTags", {
      tagsSlug: "pesantrenkilat,belajardarihati",
      page: 1,
    });
  },
};
</script>

<style lang="scss" scoped>
.ads-props {
  margin-top: 32px;
}
.ramadan {
  .peskil {
    background-color: #ffffff;
    @media only screen and (max-width: 1024px) {
      top: 124px;
    }
    ::v-deep .pesantren-kilat {
      @media only screen and (max-width: 1024px) {
        margin-top: 0;
      }
      .container {
        .content {
          border: 1px solid #c9c9c9;
          border-radius: 8px;
        }
      }
    }
  }
  .banner {
    position: relative;
    .banner-ramadan {
      width: 100%;
    }
    .headline {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: fit-content;
      @media only screen and (max-width: 1024px) {
        top: 15%;
        left: 2%;
        transform: unset;
      }
      .container {
        background: #f5f6fa;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: fit-content;
        margin: 0 auto;
        padding: 12px 32px 12px 32px;
        border-radius: 8px;
        @media only screen and (max-width: 1024px) {
          text-align: center;
          gap: 0;
          width: 303px;
        }
        .title {
          font-weight: 800;
          font-size: 36px;
          font-family: "NarasiSans", "sans-serif";
          color: #1b5482;
          margin: 0;
          @media only screen and (max-width: 1024px) {
            font-size: 16px;
          }
        }
        .city {
          font-family: "NarasiSans", "sans-serif";
          font-size: 24px;
          font-weight: 500;
          color: #1b5482;
          @media only screen and (max-width: 1024px) {
            font-size: 16px;
            font-weight: 800;
          }
        }
        .sub-title {
          font-size: 12px;
          color: #1b5482;
          @media only screen and (max-width: 1024px) {
            font-size: 7px;
          }
        }
      }
    }
  }
  .artikel {
    padding: 24px 0;
    @media only screen and (max-width: 1024px) {
      padding-bottom: 0;
      position: relative;
      margin-top: -80px;
    }
    .container {
      @media only screen and (max-width: 1024px) {
        padding: 0;
      }
      .box-artikel {
        background: #f5f6fa;
        padding: 32px;
        border-radius: 8px;
        @media only screen and (max-width: 1024px) {
          border-radius: 0;
          padding: 32px 20px;
          margin: 0;
        }
        h2 {
          text-align: center;
          margin-bottom: 24px;
        }
        .list-artikel {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(1, 1fr);
          grid-row-gap: 16px;
          grid-column-gap: 16px;
          @media only screen and (max-width: 1024px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 8px;
          }
          .box-wrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
            height: fit-content;
            @media only screen and (max-width: 1024px) {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              gap: 16px;
            }
            .card-narasi-ramadan-image {
              width: 100%;
              height: 100%;
              @media only screen and (max-width: 1024px) {
                width: 25vw;
                height: 25vw;
              }
              ::v-deep img {
                object-fit: cover;
              }
            }
            .copy-wrapper {
              display: flex;
              flex-direction: column;
              gap: 8px;
              @media only screen and (max-width: 1024px) {
                flex: 60%;
              }
              .card-narasi-ramadan-channel-category {
                display: flex;
                align-items: center;
                .channel,
                .category {
                  font-size: 12px;
                  font-weight: 400;
                  color: #1b5482;
                  margin: 0;
                  &:hover {
                    color: #1b5482 !important;
                  }
                  &.small {
                    @media only screen and (max-width: 1024px) {
                      font-size: 10px;
                    }
                  }
                }
                span {
                  font-size: 12px;
                  padding: 0 10px;
                  color: #1b5482;
                  @media only screen and (max-width: 1024px) {
                    font-size: 10px;
                  }
                  &.small {
                    @media only screen and (max-width: 1024px) {
                      font-size: 8px;
                      padding: 0 5px;
                    }
                  }
                }
              }
              .card-narasi-ramadan-title {
                font-size: 16px;
                font-weight: 500;
                color: #0c0b0d;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                @media only screen and (max-width: 1024px) {
                  font-size: 14px;
                  line-height: 18px;
                  -webkit-line-clamp: 4;
                }
              }
            }
          }
        }

        .pagination-cards {
          padding: 24px 0;

          .pagination {
            justify-content: center;
            margin: 0;
            align-items: center;
            @media only screen and (max-width: 1024px) {
              justify-content: center;
            }

            ::v-deep .arrow {
              display: none;
            }

            ::v-deep .page-item {
              a {
                font-size: 12px;
                font-weight: 400;
                color: #757575;
                background: #e0e0e0;
                width: 25px;
                height: 25px;
                margin: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }

              &.active {
                a {
                  background: #1b5482;
                  color: #9e9e9e !important;
                }
              }

              &:hover {
                a {
                  background: #1b5482;
                  color: #9e9e9e !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
