var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ramadan"},[_c('div',{staticClass:"peskil"}),_vm._v(" "),_c('section',{staticClass:"banner"},[_c('img',{staticClass:"banner-ramadan is-desktop",attrs:{"src":"/storage/images/ramadan/bg-desktop.webp"}}),_vm._v(" "),_c('img',{staticClass:"banner-ramadan is-mobile",attrs:{"src":"/storage/images/ramadan/bg-mobile.png"}}),_vm._v(" "),_c('div',{staticClass:"headline"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title-wrapper"},[_c('h1',{staticClass:"title"},[_vm._v("\n            Tafsir Surat "+_vm._s(_vm.response.surah.surat_name)+" ayat\n            "+_vm._s(this.$route.params.idAyat)+"\n          ")]),_vm._v(" "),_c('span',{staticClass:"sub-title"},[_vm._v("\n            SUMBER: LAJNAH PENTASHIHAN MUSHAF AL-QUR’AN, KEMENTERIAN AGAMA RI\n          ")])])])])]),_vm._v(" "),_c('section',{staticClass:"ayat"},[_c('ReligiHorizontalAds'),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"box-ayat"},[_c('h2',[_vm._v("بِسْمِ اللّٰهِ الرَّحْمٰنِ الرَّحِيْمِ")]),_vm._v(" "),_c('div',{staticClass:"list-ayat"},[(_vm.response.data.length > 0)?_c('div',{staticClass:"box-wrapper"},[_c('div',{staticClass:"arabic-wrapper"},[_c('div',{staticClass:"nomor-ayat"},[_c('Icons',{attrs:{"name":"surat-icon"}}),_vm._v(" "),_c('p',{staticClass:"nomor"},[_vm._v(_vm._s(this.$route.params.idAyat))])],1),_vm._v(" "),_c('p',{staticClass:"arabic"},[_vm._v("\n                "+_vm._s(_vm.response.data[this.$route.params.idAyat - 1].arabic)+"\n              ")])]),_vm._v(" "),_c('p',{staticClass:"terjemahan",domProps:{"innerHTML":_vm._s(
                _vm.response.data[this.$route.params.idAyat - 1].translation
              )}}),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"tafsir tafsir-wajiz"},[_c('p',{domProps:{"innerHTML":_vm._s(
                  _vm.response.data[this.$route.params.idAyat - 1].footnotes
                )}})])]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"narasi-ramadan"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title-wrapper"},[_c('TitleSection',{attrs:{"title":"ARTIKEL RAMADAN","color":"white"}})],1),_vm._v(" "),_c('div',{staticClass:"article-box",class:{ fitContent: _vm.tags.items.length < 6 }},_vm._l((_vm.tags.items.slice(1, 7)),function(item){return _c('Link',{key:item.id,staticClass:"card-narasi-ramadan",attrs:{"to":item.isText
                  ? {
                      name: `read-channel-slug`,
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                      query: {
                        ref: 'artikel-narasi',
                      },
                    }
                  : {
                      name: 'video-channel-slug',
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                      query: {
                        ref: 'artikel-narasi',
                      },
                    }}},[_c('div',{staticClass:"card-narasi-ramadan-thumbnail"},[_c('ImageResponsive',{staticClass:"card-narasi-ramadan-image",attrs:{"imageUrl":item.thumbnail && item.thumbnail.medium
                      ? item.thumbnail.medium
                      : 'https://stg.narasi.co.id/storage/images/dummy.png',"imageUrlMobile":item.thumbnail && item.thumbnail.medium
                      ? item.thumbnail.medium
                      : 'https://stg.narasi.co.id/storage/images/dummy.png',"fromUrl":true,"width":"100","height":"100"}}),_vm._v(" "),_c('div',{staticClass:"card-narasi-ramadan-duration",class:{ backgroundAds: item.isAdvertorial }},[(item.isAdvertorial)?_c('p',{staticClass:"advertorial"},[_vm._v("Ad")]):(item.isText)?_c('div',{staticClass:"article-reguler"},[_c('i',{staticClass:"bi bi-book-half"}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("TEKS")])]):_c('p',{staticClass:"duration"},[_vm._v("\n                    "+_vm._s(item.timeVideo ? item.timeVideo : "00:00")+"\n                  ")])])],1),_vm._v(" "),_c('div',{staticClass:"card-narasi-ramadan-body"},[_c('div',{staticClass:"card-narasi-ramadan-body-header"},[_c('div',{staticClass:"card-narasi-ramadan-channel-category"},[(item && item.channel)?_c('Link',{staticClass:"channel",attrs:{"to":{
                        name: 'program-childBrand-slugProgram',
                        params: {
                          childBrand: item.channel.program.slug,
                          slugProgram: item.channel.slug,
                        },
                        query: {
                          type: 'highlights',
                        },
                      }}},[_vm._v(_vm._s(item.channel.title))]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"card-narasi-ramadan-title"},[_vm._v("\n                    "+_vm._s(item.title)+"\n                  ")])])])])}),1),_vm._v(" "),_c('Link',{staticClass:"lihat-semua",attrs:{"to":{
              name: 'religi-artikel',
            }}},[_vm._v("Lihat Semua\n          ")]),_vm._v(" "),_c('div',{staticClass:"ads-props",attrs:{"id":"M857969ScriptRootC1408001"}})],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }