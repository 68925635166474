<template>
  <div class="ramadan">
    <div class="peskil">
      <!-- <PesantrenKilat :articles="$store.state.articles.articles_tags.items" /> -->
    </div>
    <section class="banner">
      <img
        src="/storage/images/ramadan/bg-desktop.webp"
        class="banner-ramadan is-desktop"
      />
      <img
        src="/storage/images/ramadan/bg-mobile.png"
        class="banner-ramadan is-mobile"
      />
      <div class="headline">
        <div class="container">
          <div class="title-wrapper">
            <h1 class="title">Surat {{ detailSurat.surat_name }}</h1>
            <span class="sub-title">
              SUMBER: LAJNAH PENTASHIHAN MUSHAF AL-QUR’AN, KEMENTERIAN AGAMA RI
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="ayat">
      <!-- START ADS -->
      <ReligiHorizontalAds />
      <!-- ADS END -->
      <div class="container">
        <div class="wrapper">
          <div class="filter-and-share">
            <div class="filter">
              <p>Surat</p>
              <v-select
                v-model="surat"
                placeholder="Pilih Surat"
                label="surat_name"
                v-on:input="changeRoute"
                :options="listSurat.items"
                class="select-surat"
              >
                <template slot="option" slot-scope="option">
                  {{ option.id }}. {{ option.surat_name }}
                </template>
              </v-select>
            </div>
            <div class="share is-desktop-flex">
              <p>Bagikan</p>
              <div class="share-wrapper">
                <a
                  :href="setLink('whatsapp')"
                  target="_blank"
                  rel="noopener"
                  class="sosmed-wrapper share-artikel"
                >
                  <Icons name="whatsapp" />
                </a>
                <a
                  :href="setLink('twitter')"
                  target="_blank"
                  rel="noopener"
                  class="sosmed-wrapper share-artikel"
                >
                  <Icons name="twitter-new" />
                </a>
                <a
                  :href="setLink('facebook')"
                  target="_blank"
                  rel="noopener"
                  class="sosmed-wrapper share-artikel"
                >
                  <Icons name="facebook" />
                </a>
                <div
                  class="sosmed-wrapper share-artikel"
                  @click="copyMe('link')"
                >
                  <Icons name="copy-link" />
                </div>
              </div>
            </div>
          </div>
          <div class="empty-div is-desktop"></div>
        </div>
        <div class="content">
          <div class="box-ayat">
            <h2 v-if="$route.params.id != 1">
              بِسْمِ اللّٰهِ الرَّحْمٰنِ الرَّحِيْمِ
            </h2>
            <div class="list-ayat" id="infinite-list">
              {{ listAyat.data }}
              <div
                class="box-wrapper"
                v-for="(item, index) in listAyat"
                :key="`id-index-${index}`"
                :id="`box-wrapper-${detailSurat.slug}-${index}`"
              >
                <div class="arabic-wrapper">
                  <div class="nomor-ayat">
                    <Icons name="surat-icon" />
                    <p class="nomor">{{ item.ayah }}</p>
                  </div>
                  <p class="arabic">{{ item.arabic }}</p>
                </div>
                <p class="terjemahan" v-html="item.translation"></p>
                <div class="btn-wrapper">
                  <div class="cursor-pointer btn-audio">
                    <audio
                      :src="`https://media.qurankemenag.net/audio/Abu_Bakr_Ash-Shaatree_aac64/${formatN(
                        detailSurat.id
                      )}${formatN(index + 1)}.m4a`"
                      type="audio/mpeg"
                      preload="none"
                      :id="`sound-${detailSurat.slug}-${index}`"
                    ></audio>
                    <div
                      class="play"
                      :id="`playTrigger-${detailSurat.slug}-${index}`"
                      @click="play(index)"
                    >
                      <i class="material-icons">play_arrow</i>
                    </div>
                  </div>
                  <Link
                    :to="{
                      name: 'religi-quran-digital-slug-idAyat',
                      params: {
                        slug: detailSurat.slug,
                        idAyat: item.ayah,
                      },
                    }"
                    class="tafsir-wrapper"
                  >
                    <Icons name="black-book" class="icon book-icon" />
                    <p>Tafsir ayat ke-{{ item.ayah }}</p>
                  </Link>
                  <div class="popover-wrapper">
                    <Icons name="three-dots" class="three-dots" />
                    <div class="popover-content">
                      <span
                        v-if="surat"
                        @click="
                          copyMe(
                            `Qur'an Digital Narasi Q.S ${surat.surat_name}:${item.no_ayat} - ${item.text} - &quot;${item.translation}&quot; - ${url}`
                          )
                        "
                      >
                        <Icons name="copy" class="icon copy-icon" />
                        <p>Copy</p>
                      </span>
                      <a
                        target="_blank"
                        rel="noopener"
                        :href="`https://www.facebook.com/sharer.php?u=${url}?utm_source=facebook&utm_medium=share`"
                      >
                        <Icons
                          name="black-facebook"
                          class="icon facebook-icon"
                        />
                        <p>Facebook</p>
                      </a>
                      <a
                        target="_blank"
                        rel="noopener"
                        :href="`https://twitter.com/intent/tweet?url=${url}?utm_source=twitter&utm_medium=share`"
                      >
                        <Icons name="black-twitter" class="icon twitter-icon" />
                        <p>Twitter</p>
                      </a>
                      <a
                        v-if="surat"
                        target="_blank"
                        rel="noopener"
                        :href="`https://wa.me/?text=Qur'an Digital Narasi Q.S ${surat.surat_name}:${item.no_ayat} - ${item.text} - &quot;${item.translation}&quot; - ${url}%3Futm_source=whatsapp%26utm_medium=share`"
                      >
                        <Icons
                          name="black-whatsapp"
                          class="icon whatsapp-icon"
                        />
                        <p>Whatsapp</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="narasi-ramadan">
            <div class="container">
              <div class="title-wrapper">
                <TitleSection title="ARTIKEL RAMADAN" color="white" />
              </div>
              <div
                class="article-box"
                :class="{ fitContent: tags.items.length < 6 }"
              >
                <Link
                  v-for="item in tags.items.slice(1, 7)"
                  :key="item.id"
                  :to="
                    item.isText
                      ? {
                          name: `read-channel-slug`,
                          params: {
                            channel: item.channel.slug,
                            slug: item.slug,
                          },
                          query: {
                            ref: 'artikel-narasi',
                          },
                        }
                      : {
                          name: 'video-channel-slug',
                          params: {
                            channel: item.channel.slug,
                            slug: item.slug,
                          },
                          query: {
                            ref: 'artikel-narasi',
                          },
                        }
                  "
                  class="card-narasi-ramadan"
                >
                  <div class="card-narasi-ramadan-thumbnail">
                    <ImageResponsive
                      :imageUrl="
                        item.thumbnail && item.thumbnail.medium
                          ? item.thumbnail.medium
                          : 'https://stg.narasi.co.id/storage/images/dummy.png'
                      "
                      :imageUrlMobile="
                        item.thumbnail && item.thumbnail.medium
                          ? item.thumbnail.medium
                          : 'https://stg.narasi.co.id/storage/images/dummy.png'
                      "
                      :fromUrl="true"
                      width="100"
                      height="100"
                      class="card-narasi-ramadan-image"
                    />
                    <div
                      class="card-narasi-ramadan-duration"
                      :class="{ backgroundAds: item.isAdvertorial }"
                    >
                      <p class="advertorial" v-if="item.isAdvertorial">Ad</p>
                      <div class="article-reguler" v-else-if="item.isText">
                        <!-- <Icons name="book" /> -->
                        <i class="bi bi-book-half"></i>
                        <p class="text">TEKS</p>
                      </div>
                      <p class="duration" v-else>
                        {{ item.timeVideo ? item.timeVideo : "00:00" }}
                      </p>
                    </div>
                  </div>
                  <div class="card-narasi-ramadan-body">
                    <div class="card-narasi-ramadan-body-header">
                      <div class="card-narasi-ramadan-channel-category">
                        <Link
                          v-if="item && item.channel"
                          :to="{
                            name: 'program-childBrand-slugProgram',
                            params: {
                              childBrand: item.channel.program.slug,
                              slugProgram: item.channel.slug,
                            },
                            query: {
                              type: 'highlights',
                            },
                          }"
                          class="channel"
                          >{{ item.channel.title }}</Link
                        >
                        <!-- <span v-show="item.category ? item.category.slug : ''"
                          >&bull;</span
                        >
                        <Link
                          v-show="item.category ? item.category.slug : ''"
                          :to="{
                            name: 'interest',
                            query: {
                              type: item.category ? item.category.slug : '',
                            },
                          }"
                          class="category"
                          >{{
                            item.category ? item.category.title : ""
                          }}</Link
                        > -->
                      </div>
                      <div class="card-narasi-ramadan-title">
                        {{ item.title }}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <Link
                :to="{
                  name: 'religi-artikel',
                }"
                class="lihat-semua"
                >Lihat Semua
              </Link>
              <!-- ADS PROPS START -->
              <div id="M857969ScriptRootC1408001" class="ads-props"></div>
              <!-- ADS PROPS END -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import config from "../../../../config";
import { mapGetters, mapState } from "vuex";
import Icons from "@/components/Icons";
import Link from "@/components/Link";
import TitleSection from "@/components/TitleSection";
import ImageResponsive from "@/components/ImageResponsive";
import PesantrenKilat from "@/components/ramadan/PesantrenKilat.vue";
import ReligiHorizontalAds from "@/components/ads/religi/Horizontal.vue";

export default {
  async asyncData({ error, store, route, app, params, redirect }) {
    try {
      const response = await app.$axios.$get(
        `${config.CORE_SERVICE_API}ramadhan/surah/${params.slug}`
      );
      store.commit("seo/SET_SEO", {
        title: `Al-Quran Surat ${response.surah.surat_name} Terjemahan Bahasa Indonesia dan Tafsir Ayat`,
        desc: `Baca Al-Quran Online Surat ${response.surah.surat_name} Lengkap dengan bacaan Arab, Latin, Terjemahan Bahasa Indonesia, Tanda Waqaf dan Tafsir Ayat hanya di Narasi.`,
        image: "https://narasi.tv/icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    ImageResponsive,
    TitleSection,
    Icons,
    Link,
    PesantrenKilat,
    ReligiHorizontalAds,
  },
  data() {
    return {
      search: "",
      page: 1,
      url: "",
      surat: {},
    };
  },
  head() {
    return {
      ...this.headReturn,
      script: [{ src: "https://jsc.mgid.com/p/r/props.narasi.tv.1408001.js" }],
    };
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      responseAyat: (state) => {
        return state.ramadan.ayat;
      },
      listAyat: (state) => {
        return state.ramadan.ayat.items.data;
      },
      ayat: (state) => {
        return state.ramadan.ayat.items;
      },
      detailSurat: (state) => {
        return state.ramadan.ayat.surat;
      },
      listSurat: (state) => {
        return state.ramadan.surat;
      },
      tags: (state) => {
        return state.videos.tags;
      },
    }),
  },
  methods: {
    changeRoute(e) {
      window.location.href = `/religi/quran-digital/${e.slug}`;
    },
    formatN(n) {
      let str = "" + n;
      let pad = "000";
      return pad.substring(0, pad.length - str.length) + str;
    },
    play(indexPlay) {
      const audios = document.getElementsByTagName(`audio`);
      for (let index = 0; index < audios.length; index++) {
        if (indexPlay === index) {
          if (audios[index].duration > 0 && !audios[index].paused) {
            let element = document.getElementById(
              `playTrigger-${this.detailSurat.slug}-${index}`
            );
            element.innerHTML = "<i class='material-icons'>play_arrow</i>";
            element.classList.remove("pause");
            audios[index].pause();
          } else {
            let element = document.getElementById(
              `playTrigger-${this.detailSurat.slug}-${index}`
            );
            element.innerHTML = "<i class='material-icons pause'>pause</i>";
            element.classList.add("pause");
            audios[index].play();
          }

          audios[index].addEventListener("ended", () => {
            let element = document.getElementById(
              `playTrigger-${this.detailSurat.slug}-${index}`
            );
            element.innerHTML = "<i class='material-icons'>play_arrow</i>";
            element.classList.remove("pause");
            let next = document.getElementById(
              `playTrigger-${this.detailSurat.slug}-${index + 1}`
            );
            document
              .getElementById(`box-wrapper-${this.detailSurat.slug}-${index}`)
              .scrollIntoView({
                behavior: "smooth",
              });

            if (next === null) {
              let interval = setInterval(() => {
                next = document.getElementById(
                  `playTrigger-${this.detailSurat.slug}-${index + 1}`
                );
                if (next !== null) {
                  next.click();
                  document
                    .getElementById(
                      `box-wrapper-${this.detailSurat.slug}-${index}`
                    )
                    .scrollIntoView({
                      behavior: "smooth",
                    });
                  clearInterval(interval);
                }
              }, 1000);
            } else {
              next.click();
            }
          });
        } else {
          audios[index].pause();
          let element = document.getElementById(
            `playTrigger-${this.detailSurat.slug}-${index}`
          );
          element.innerHTML = "<i class='material-icons'>play_arrow</i>";
          element.classList.remove("pause");
        }
      }
    },
    setLink(param) {
      switch (param) {
        case "whatsapp":
          return (
            "https://wa.me/?text=" +
            this.url +
            "%3Futm_source=whatsapp%26utm_medium=share"
          );
        case "facebook":
          return (
            "https://www.facebook.com/sharer.php?u=" +
            this.url +
            "?utm_source=facebook&utm_medium=share"
          );
        case "twitter":
          return (
            "https://twitter.com/intent/tweet?url=" +
            this.url +
            "?utm_source=twitter&utm_medium=share"
          );
      }
    },
    copyMe(params) {
      if (params === "link") {
        navigator.clipboard.writeText(
          this.url + "?utm_source=copy_link&utm_medium=share"
        );
        this.$toast.success("Copy Link Success");
      } else {
        navigator.clipboard.writeText(params);
        this.$toast.success("Copy Ayat Success");
      }
    },
  },
  async fetch() {
    await this.$store.dispatch("videos/getTags", {
      tagsSlug: "ramadan-dan-religi",
      page: this.page,
      firstLoad: true,
    });
    await this.$store.dispatch("ramadan/getSurat");
    await this.$store
      .dispatch("ramadan/getAyat", {
        link: `${config.CORE_SERVICE_API}ramadhan/surah/${this.$route.params.slug}?limit=${this.detailSurat.count_ayat}`,
        firstLoad: true,
      })
      .then(() => {
        this.surat = this.detailSurat;
      });
  },
  mounted() {
    this.url = window.location.href;
    this.$store.dispatch("articles/getArticlesByTags", {
      tagsSlug: "pesantrenkilat,belajardarihati",
      page: 1,
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .adsbygoogle {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}
.ads-props {
  margin-top: 32px;
}
.ramadan {
  .peskil {
    background-color: #ffffff;
    @media only screen and (max-width: 1024px) {
      top: 124px;
    }
    ::v-deep .pesantren-kilat {
      @media only screen and (max-width: 1024px) {
        margin-top: 0;
      }
      .container {
        .content {
          border: 1px solid #c9c9c9;
          border-radius: 8px;
        }
      }
    }
  }
  .banner {
    position: relative;
    .banner-ramadan {
      width: 100%;
    }
    .headline {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: fit-content;
      @media only screen and (max-width: 1024px) {
        top: 86%;
        left: 50%;
      }
      .container {
        .title-wrapper {
          background: rgba(245, 246, 250, 1);
          backdrop-filter: blur(2px);
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: fit-content;
          margin: 0 auto;
          padding: 12px 32px 12px 32px;
          border-radius: 8px;
          @media only screen and (max-width: 1024px) {
            text-align: center;
            gap: 0;
            width: 303px;
          }
          .title {
            font-weight: 800;
            font-size: 36px;
            font-family: "NarasiSans", "sans-serif";
            color: #1b5482;
            margin: 0;
            @media only screen and (max-width: 1024px) {
              font-size: 16px;
            }
          }
          .city {
            font-family: "NarasiSans", "sans-serif";
            font-size: 24px;
            font-weight: 500;
            color: #1b5482;
            @media only screen and (max-width: 1024px) {
              font-size: 16px;
              font-weight: 800;
            }
          }
          .sub-title {
            font-size: 12px;
            color: #1b5482;
            @media only screen and (max-width: 1024px) {
              font-size: 7px;
            }
          }
        }
      }
    }
  }
  .ayat {
    background: #1b5482;
    padding-bottom: 48px;
    @media only screen and (max-width: 1024px) {
      padding-bottom: 32px;
      padding-top: 20px;
    }
    .container {
      @media only screen and (max-width: 1024px) {
        padding: 0;
      }
      .wrapper {
        display: flex;
        gap: 16px;
        // @media only screen and (max-width: 1024px) {
        //   margin-top: -63px;
        // }
        .filter-and-share {
          flex: 70%;
          display: flex;
          justify-content: space-between;
          @media only screen and (max-width: 1024px) {
            justify-content: center;
          }
          .filter {
            display: flex;
            align-items: center;
            @media only screen and (max-width: 1024px) {
              width: 100%;
              justify-content: center;
            }
            p {
              font-size: 14px;
              font-weight: 500;
              color: #f1f1f1;
              margin: 0;
              margin-right: 8px;
              @media only screen and (max-width: 1024px) {
                display: none;
              }
            }
            .v-select {
              width: 300px;
              border-radius: 4px;
              height: fit-content;
              @media only screen and (max-width: 1024px) {
                width: 90%;
              }
              ::v-deep {
                input {
                  @media only screen and (max-width: 1024px) {
                    font-size: 12px;
                  }
                }
                ul {
                  li {
                    &:hover {
                      background: #8166c8;
                      color: #ffe900;
                    }
                  }
                }
              }
            }
          }
          .share {
            display: flex;
            align-items: center;
            p {
              font-size: 14px;
              font-weight: 500;
              color: #f1f1f1;
              margin: 0;
              margin-right: 8px;
              @media only screen and (max-width: 1024px) {
                margin-right: 16px;
              }
            }
            .share-wrapper {
              display: flex;
              align-items: center;
              cursor: pointer;
              .sosmed-wrapper {
                svg {
                  width: 32px;
                  height: 32px;
                  margin-left: 8px;
                  @media only screen and (max-width: 1024px) {
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                    margin-left: unset;
                  }
                }
              }
            }
          }
        }
        .empty-div {
          flex: 30%;
        }
      }
      .content {
        display: flex;
        gap: 16px;
        margin-top: 16px;
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
          margin-top: 16px;
        }
        .box-ayat {
          flex: 65%;
          background: #f5f6fa;
          padding: 24px 22px;
          border-radius: 8px;
          @media only screen and (max-width: 1024px) {
            flex: 100%;
            border-radius: 0;
          }
          h2 {
            text-align: center;
            margin-bottom: 24px;
            font-size: 28px;
            font-family: "Lpmq", "roboto";
            @media only screen and (max-width: 1024px) {
              font-size: 24px;
            }
          }
          .list-ayat {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-row-gap: 16px;
            grid-column-gap: 16px;
            max-height: 50vw;
            overflow: auto;
            @media only screen and (max-width: 1024px) {
              grid-template-columns: repeat(1, 1fr);
              max-height: 100vh;
            }
            .box-wrapper {
              display: flex;
              flex-direction: column;
              gap: 8px;
              padding: 24px;
              border-bottom: 1px solid #d9d9d9;
              @media only screen and (max-width: 1024px) {
                gap: 8px;
              }
              .arabic-wrapper {
                display: flex;
                align-items: center;
                gap: 16px;
                align-self: flex-end;
                margin-bottom: 8px;
                .nomor-ayat {
                  position: relative;
                  @media only screen and (max-width: 1024px) {
                    display: none;
                  }
                  svg {
                    width: 30px;
                    height: auto;
                  }
                  .nomor {
                    font-size: 12px;
                    color: #1b5482;
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @media only screen and (max-width: 1024px) {
                      font-size: 14px;
                    }
                  }
                }
                .arabic {
                  font-size: 28px;
                  line-height: 220%;
                  text-align: right;
                  font-family: "Lpmq", "roboto";
                  color: #202020;
                  margin: 0;
                  @media only screen and (max-width: 1024px) {
                    font-size: 24px;
                  }
                }
              }
              .terjemahan {
                font-size: 16px;
                color: #202020;
                margin: 0;
                align-self: flex-start;
                @media only screen and (max-width: 1024px) {
                  font-size: 14px;
                }
              }
              .btn-wrapper {
                position: relative;
                align-self: flex-end;
                display: flex;
                align-items: center;
                gap: 16px;
                padding: 10px 0;
                ::v-deep .btn-audio {
                  .play {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    background: #ffffff;
                    padding: 2px 6px;
                    border: 1px solid #d9d9d9;
                    border-radius: 8px;
                    i {
                      font-size: 16px;
                    }
                    p {
                      font-size: 12px;
                      color: #202020;
                      margin: 0;
                    }
                    &.pause {
                      background: #1b5482;
                      border: none;
                      i,
                      p {
                        color: #fce600;
                      }
                    }
                  }
                }
                .tafsir-wrapper {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  background: #ffffff;
                  padding: 2px 6px;
                  border: 1px solid #d9d9d9;
                  border-radius: 8px;
                  svg {
                    width: 14px;
                    height: auto;
                    display: block;
                  }
                  p {
                    font-size: 12px;
                    color: #202020;
                    margin: 0;
                  }
                }
                .popover-wrapper {
                  position: relative;
                  height: 100%;
                  display: flex;
                  &:hover {
                    .popover-content {
                      z-index: 10;
                      opacity: 1;
                      visibility: visible;
                      transform: translate(0, -20px);
                      transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
                    }
                  }
                  .three-dots {
                    width: 20px;
                    height: auto;
                    display: block;
                    position: relative;
                    cursor: pointer;
                  }
                  .popover-content {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    top: 45px;
                    left: -80px;
                    transform: translate(0, 10px);
                    background-color: #ffffff;
                    padding: 8px 16px;
                    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
                    width: auto;
                    border-radius: 4px;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    &::before {
                      position: absolute;
                      z-index: -1;
                      content: "";
                      right: calc(14% - 10px);
                      top: -8px;
                      border: 1px solid #d9d9d9;
                      transition-duration: 0.3s;
                      transition-property: transform;
                    }
                    span {
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      gap: 8px;
                      .icon {
                        flex: 30%;
                        &.book-icon {
                          width: 22px;
                          height: auto;
                        }
                        &.copy-icon {
                          height: 20px;
                          width: auto;
                        }
                        &.facebook-icon {
                          height: 24px;
                          width: auto;
                        }
                        &.twitter-icon {
                          height: 16px;
                          width: auto;
                        }
                        &.whatsapp-icon {
                          height: 20px;
                          width: auto;
                        }
                      }
                      p {
                        flex: 70%;
                        font-size: 14px;
                        color: #202020;
                        margin: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .narasi-ramadan {
          flex: 30%;
          @media only screen and (max-width: 1024px) {
            margin-top: 24px;
            flex: 100%;
          }
          .container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            @media only screen and (max-width: 1024px) {
              padding: 0 0.75rem;
            }
            .title-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            }

            .lihat-semua {
              text-align: center;
              font-size: 16px;
              font-weight: 500;
              color: #ffe900;
              text-decoration: underline !important;
              &:hover {
                color: #ffe900 !important;
              }
              @media only screen and (max-width: 1024px) {
                font-size: 12px;
              }
            }

            .article-box {
              flex: 70%;
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              grid-column-gap: 0;
              grid-row-gap: 10px;
              @media only screen and (max-width: 1024px) {
                grid-template-columns: repeat(1, 1fr);
                grid-row-gap: 10px;
              }
              &.fitContent {
                height: fit-content;
              }
              .card-narasi-ramadan {
                width: 100%;
                height: fit-content;
                display: flex;
                flex-direction: row;
                flex: 30%;
                gap: 16px;
                padding-bottom: 10px;
                border-bottom: 1px solid #614f90;
                @media only screen and (max-width: 1024px) {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                }

                &:last-child {
                  border: none;
                  padding: 0;
                }
                .card-narasi-ramadan-thumbnail {
                  position: relative;
                  // border-radius: 10px;
                  overflow: hidden;
                  flex: none;
                  width: 5vw;
                  height: 5vw;
                  @media only screen and (max-width: 1024px) {
                    // border-radius: 6px;
                    width: 25vw;
                    height: 25vw;
                  }
                  .card-narasi-ramadan-image {
                    width: 100%;
                    height: 100%;
                    ::v-deep img {
                      object-fit: cover;
                    }
                  }
                  .card-narasi-ramadan-duration {
                    background: rgba(0, 0, 0, 0.7);
                    padding: 3px 6px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    border-top-left-radius: 10px;
                    @media only screen and (max-width: 1024px) {
                      border-top-left-radius: 6px;
                    }
                    &.backgroundAds {
                      background: rgba(128, 128, 128, 0.7);
                      padding: 3px 8px;
                    }
                    .advertorial {
                      font-size: 12px;
                      font-weight: 500;
                      color: #ffffff;
                      margin: 0;
                      @media only screen and (max-width: 1024px) {
                        font-size: 10px;
                      }
                    }
                    .article-reguler {
                      display: flex;
                      align-items: center;
                      .bi-book-half {
                        width: 16px;
                        height: auto;
                        margin-right: 2px;
                        color: #ffffff;
                      }
                      .text {
                        font-size: 10px;
                        font-weight: 500;
                        color: #ffffff;
                        margin: 0;
                      }
                    }
                    .duration {
                      font-size: 12px;
                      font-weight: 500;
                      color: #ffffff;
                      margin: 0;
                      @media only screen and (max-width: 1024px) {
                        font-size: 10px;
                      }
                    }
                  }
                }
                .card-narasi-ramadan-body {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  flex: auto;
                  @media only screen and (max-width: 1024px) {
                    height: 25vw;
                    justify-content: unset;
                    flex: inherit;
                  }
                  .card-narasi-ramadan-body-header {
                    .card-narasi-ramadan-channel-category {
                      display: flex;
                      align-items: center;
                      margin-bottom: 8px;
                      .channel,
                      .category {
                        font-size: 12px;
                        font-weight: 400;
                        color: #ffe900;
                        margin: 0;
                        &:hover {
                          color: #ffe900 !important;
                        }
                        &.small {
                          @media only screen and (max-width: 1024px) {
                            font-size: 10px;
                          }
                        }
                      }
                      span {
                        font-size: 12px;
                        padding: 0 10px;
                        color: #ffe900;
                        @media only screen and (max-width: 1024px) {
                          font-size: 10px;
                        }
                        &.small {
                          @media only screen and (max-width: 1024px) {
                            font-size: 8px;
                            padding: 0 5px;
                          }
                        }
                      }
                    }
                    .card-narasi-ramadan-title {
                      font-size: 16px;
                      font-weight: 500;
                      color: #f1f1f1;
                      margin-bottom: 8px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      -webkit-line-clamp: 3;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      @media only screen and (max-width: 1024px) {
                        font-size: 14px;
                        line-height: 18px;
                        -webkit-line-clamp: 4;
                      }
                    }
                  }
                  .card-narasi-ramadan-body-footer {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .category {
                      display: none;
                      @media only screen and (max-width: 1024px) {
                        display: block;
                        font-size: 12px;
                        font-weight: 400;
                        color: #ffe900;
                        margin: 0;
                        padding-right: 5px;
                        &.small {
                          font-size: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
