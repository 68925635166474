<template>
  <div class="ramadan">
    <div class="peskil">
      <!-- <PesantrenKilat :articles="$store.state.articles.articles_tags.items" /> -->
    </div>
    <section class="banner">
      <img
        src="/storage/images/ramadan/bg-desktop.webp"
        class="banner-ramadan is-desktop"
        alt=""
      />
      <img
        src="/storage/images/ramadan/bg-mobile.png"
        class="banner-ramadan is-mobile"
        alt=""
      />
      <div class="headline">
        <div class="container">
          <div class="title-wrapper">
            <h1 class="title">Jadwal Sholat dan Imsakiyah</h1>
            <span class="city"
              >untuk {{ oneKota.kabkoNama }} dan Sekitarnya Hari Ini</span
            >
            <span class="sub-title">SUMBER: KEMENTERIAN AGAMA RI</span>
          </div>
        </div>
      </div>
    </section>
    <section class="quran-dan-jadwal">
      <div class="container">
        <div class="schedule">
          <div class="quran">
            <ImageResponsive imageUrl="ramadan/quran.png" class="is-desktop" />
            <div class="title is-desktop-flex">
              <Icons name="quran-icon" />
              <p>Quran Digital</p>
            </div>
            <div class="desc is-desktop">
              Baca, pelajari, dan pahami Quran dengan memanfaatkan fitur audio
              bacaan, terjemahan, beserta tafsirnya di sini.
            </div>
            <Link :to="{ name: 'religi-quran-digital' }" class="quran-btn">
              <Icons name="quran-icon" class="is-mobile" />
              <p>Mulai baca Qur’an</p>
              <Icons name="arrow-right" />
            </Link>
          </div>
          <div class="jadwal-sholat">
            <div class="jadwal">
              <div class="list-jadwal-sholat">
                <div
                  v-for="(item, key, index) in listJadwal.items"
                  :key="index"
                  class="box-wrapper"
                  :class="{ active: key == 'imsak' || key == 'maghrib' }"
                >
                  <div class="box">
                    <p class="waktu-sholat">{{ key }}</p>
                    <p class="jam-sholat">{{ item }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="filter-jadwal">
              <div class="copy-wrapper is-desktop">
                <p class="title">Jadwal Sholat</p>
                <p class="desc">
                  Silakan pilih kabupaten atau kota yang Anda inginkan di
                  samping ini.
                </p>
              </div>
              <div class="select-box">
                <v-select
                  v-model="kota"
                  placeholder="Pilih kota"
                  label="kabkoNama"
                  :options="listKota"
                  class="select-kota"
                ></v-select>
                <v-select
                  v-model="bulan"
                  placeholder="Pilih bulan"
                  label="label"
                  :options="[
                    { label: 'Januari', value: 1 },
                    { label: 'Febuari', value: 2 },
                    { label: 'Maret', value: 3 },
                    { label: 'April', value: 4 },
                    { label: 'Mei', value: 5 },
                    { label: 'Juni', value: 6 },
                    { label: 'Juli', value: 7 },
                    { label: 'Agustus', value: 8 },
                    { label: 'September', value: 9 },
                    { label: 'Oktober', value: 10 },
                    { label: 'November', value: 11 },
                    { label: 'Desember', value: 12 },
                  ]"
                  class="select-bulan"
                ></v-select>
                <v-select
                  v-model="tahun"
                  placeholder="Pilih tahun"
                  label="kabkoNama"
                  :options="[2023, 2024, 2025]"
                  class="select-tahun"
                ></v-select>
                <div class="btn-box">
                  <Link
                    :to="{
                      name: 'religi-jadwal-sholat-slug',
                      params: {
                        slug: filter.kota.slug,
                      },
                    }"
                    class="btn-filter"
                    :class="{ disable: disable }"
                    @click.native="filterJadwal"
                  >
                    Lihat Jadwal
                  </Link>
                  <div class="is-desktop"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- START ADS -->
      <ReligiHorizontalAds />
      <!-- ADS END -->
    </section>
    <section class="content">
      <div class="container">
        <div class="jadwal-bulanan">
          <table>
            <tbody>
              <tr>
                <th class="box desktop">TANGGAL</th>
                <th class="box mobile">HARI</th>
                <th class="box">IMSAK</th>
                <th class="box">SUBUH</th>
                <th class="box">DZUHUR</th>
                <th class="box">ASHAR</th>
                <th class="box">MAGHRIB</th>
                <th class="box">ISYA</th>
              </tr>
              <tr
                v-for="(item, key, i) in listJadwalBulanan.items"
                :key="`jadwal-bulanan-${i}`"
                class="body"
                :class="{ active: listJadwal.tanggal === item.tanggal }"
              >
                <td class="box desktop">
                  {{
                    `${i + 1} ${$moment(key).local().format("MMMM")} ${tahun}`
                  }}
                </td>
                <td class="box mobile">
                  {{ `${i + 1} ${$moment(key).local().format("MMM")}` }}
                </td>
                <td class="box">{{ item.imsak }}</td>
                <td class="box">{{ item.subuh }}</td>
                <td class="box">{{ item.dzuhur }}</td>
                <td class="box">{{ item.ashar }}</td>
                <td class="box">{{ item.maghrib }}</td>
                <td class="box">{{ item.isya }}</td>
              </tr>
            </tbody>
          </table>
          <div class="btn-wrapper is-desktop-flex container">
            <Link :to="{ name: 'religi' }"
              ><Icons name="arrow-left" color="#FFE900" />Kembali ke tampilan
              harian</Link
            >
            <div class="share-wrapper">
              <a
                :href="setLink('whatsapp')"
                target="_blank"
                rel="noopener"
                class="sosmed-wrapper share-artikel"
              >
                <Icons name="whatsapp" />
              </a>
              <a
                :href="setLink('twitter')"
                target="_blank"
                rel="noopener"
                class="sosmed-wrapper share-artikel"
              >
                <Icons name="twitter-new" />
              </a>
              <a
                :href="setLink('facebook')"
                target="_blank"
                rel="noopener"
                class="sosmed-wrapper share-artikel"
              >
                <Icons name="facebook" />
              </a>
              <div class="sosmed-wrapper share-artikel" @click="copyLink()">
                <Icons name="copy-link" />
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrapper is-mobile-flex container">
          <Link :to="{ name: 'religi' }"
            ><Icons name="arrow-left" color="#FFE900" />Kembali ke tampilan
            harian</Link
          >
          <div class="share-wrapper">
            <a
              :href="setLink('whatsapp')"
              target="_blank"
              rel="noopener"
              class="sosmed-wrapper share-artikel"
            >
              <Icons name="whatsapp" />
            </a>
            <a
              :href="setLink('twitter')"
              target="_blank"
              rel="noopener"
              class="sosmed-wrapper share-artikel"
            >
              <Icons name="twitter-new" />
            </a>
            <a
              :href="setLink('facebook')"
              target="_blank"
              rel="noopener"
              class="sosmed-wrapper share-artikel"
            >
              <Icons name="facebook" />
            </a>
            <div class="sosmed-wrapper share-artikel" @click="copyLink()">
              <Icons name="copy-link" />
            </div>
          </div>
        </div>
        <Link :to="{ name: 'religi-quran-digital' }" class="quran-btn-mobile">
          <div class="container">
            <Icons name="quran-icon" class="is-mobile" />
            <p>Mulai baca Qur’an</p>
            <Icons name="arrow-right" />
          </div>
        </Link>
        <div class="narasi-ramadan">
          <div class="container">
            <div class="title-wrapper">
              <TitleSection title="ARTIKEL RAMADAN" color="white" />
            </div>
            <div
              class="article-box"
              :class="{ fitContent: tags.items.length < 6 }"
            >
              <Link
                v-for="item in tags.items.slice(1, 7)"
                :key="item.id"
                :to="{
                  name: item.isText
                    ? 'read-channel-slug'
                    : 'video-channel-slug',
                  params: {
                    channel: item.channel.slug,
                    slug: item.slug,
                  },
                  query: {
                    ref: 'artikel-ramadan',
                  },
                }"
                class="card-narasi-ramadan"
              >
                <div class="card-narasi-ramadan-thumbnail">
                  <ImageResponsive
                    :imageUrl="
                      item.thumbnail && item.thumbnail.medium
                        ? item.thumbnail.medium
                        : 'https://stg.narasi.co.id/storage/images/dummy.png'
                    "
                    :imageUrlMobile="
                      item.thumbnail && item.thumbnail.medium
                        ? item.thumbnail.medium
                        : 'https://stg.narasi.co.id/storage/images/dummy.png'
                    "
                    :fromUrl="true"
                    width="100"
                    height="100"
                    class="card-narasi-ramadan-image"
                  />
                  <div
                    class="card-narasi-ramadan-duration"
                    :class="{ backgroundAds: item.isAdvertorial }"
                  >
                    <p class="advertorial" v-if="item.isAdvertorial">Ad</p>
                    <div class="article-reguler" v-else-if="item.isText">
                      <!-- <Icons name="book" /> -->
                      <i class="bi bi-book-half"></i>
                      <p class="text">TEKS</p>
                    </div>
                    <p class="duration" v-else>
                      {{ item.timeVideo ? item.timeVideo : "00:00" }}
                    </p>
                  </div>
                </div>
                <div class="card-narasi-ramadan-body">
                  <div class="card-narasi-ramadan-body-header">
                    <div class="card-narasi-ramadan-channel-category">
                      <Link
                        v-if="item && item.channel"
                        :to="{
                          name: 'program-childBrand-slugProgram',
                          params: {
                            childBrand: item.channel.program.slug,
                            slugProgram: item.channel.slug,
                          },
                          query: {
                            type: 'highlights',
                          },
                        }"
                        class="channel"
                        >{{ item.channel.title }}</Link
                      >
                    </div>
                    <div class="card-narasi-ramadan-title">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <Link
              :to="{
                name: 'religi-artikel',
              }"
              class="lihat-semua"
              >Lihat Semua
            </Link>
            <!-- ADS PROPS START -->
            <div id="M857969ScriptRootC1408001" class="ads-props"></div>
            <!-- ADS PROPS END -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import config from "../../../config";
import { mapGetters, mapState } from "vuex";
import CardTwentyFour from "/components/cards/CardTwentyFour.vue";
import ImageResponsive from "@/components/ImageResponsive";
import Icons from "@/components/Icons";
import Link from "@/components/Link";
import TitleSection from "@/components/TitleSection";
import PesantrenKilat from "@/components/ramadan/PesantrenKilat.vue";
import ReligiHorizontalAds from "@/components/ads/religi/Horizontal.vue";

export default {
  async asyncData({ error, store, route, app, params, redirect }) {
    try {
      store.commit("seo/SET_SEO", {
        title: `Jadwal Sholat & Imsakiyah ${params.slug
          .split("-")
          .join(" ")} dan Sekitarnya ${app
          .$moment()
          .local()
          .format("MMMM")} ${app.$moment().local().format("YYYY")}`,
        desc: `Jadwal Sholat, Imsakiyah, Buka Puasa dan Sholat Subuh, Dzuhur, Ashar, Magrib, Isya ${params.slug
          .split("-")
          .join(" ")} dan Sekitarnya ${app
          .$moment()
          .local()
          .format("MMMM")} ${app
          .$moment()
          .local()
          .format("YYYY")} Terbaru dan Terlengkap Hanya di Narasi.`,
        image: "https://narasi.tv/icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    PesantrenKilat,
    TitleSection,
    Link,
    Icons,
    CardTwentyFour,
    ImageResponsive,
    ReligiHorizontalAds,
  },
  data() {
    return {
      url: "",
      page: 1,
      disable: false,
      masks: {
        input: "WWWW, D MMMM YYYY",
      },
      labelKota: "KOTA JAKARTA",
      provinsi: {
        provKode: "c51ce410c124a10e0db5e4b97fc2af39",
        provNama: "DKI JAKARTA",
      },
      objKota: {
        provKode: "c51ce410c124a10e0db5e4b97fc2af39",
        provNama: "DKI JAKARTA",
        kabkoKode: "58a2fc6ed39fd083f55d4182bf88826d",
        kabkoNama: "KOTA JAKARTA",
        slug: "kota-jakarta",
      },
      kota: this.objKota,
      slugKota: "kota-jakarta",
      bulan: {
        label: this.$moment(new Date()).local().format("MMMM"),
        value: this.$moment(new Date()).local().month() + 1,
      },
      tahun: this.$moment(new Date()).local().year(),
      provKode: "c51ce410c124a10e0db5e4b97fc2af39",
      kabkoKode: "58a2fc6ed39fd083f55d4182bf88826d",
      dateKode: this.$moment(new Date()).local().format("YYYY-MM-D"),
      page: 1,
    };
  },
  watch: {
    kota(newValue, oldValue) {
      if (newValue) {
        this.objKota = {
          provKode: newValue.provKode,
          provNama: newValue.provNama,
          kabkoKode: newValue.kabkoKode,
          kabkoNama: newValue.kabkoNama,
          slug: newValue.slug,
        };
      }
      this.$store.commit("ramadan/SET_FILTER", {
        bulan: this.filter.bulan,
        tahun: this.filter.tahun,
        kota: this.objKota,
      });
    },
    bulan(newValue, oldValue) {
      if (newValue) {
        this.bulan = newValue;
      }
      this.$store.commit("ramadan/SET_FILTER", {
        bulan: this.bulan.value,
        tahun: this.filter.tahun,
        kota: this.filter.kota,
      });
    },
    tahun(newValue, oldValue) {
      if (newValue) {
        this.tahun = newValue;
      }
      this.$store.commit("ramadan/SET_FILTER", {
        bulan: this.filter.bulan,
        tahun: this.tahun,
        kota: this.filter.kota,
      });
    },
  },
  head() {
    return {
      ...this.headReturn,
      script: [{ src: "https://jsc.mgid.com/p/r/props.narasi.tv.1408001.js" }],
    };
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      filter: (state) => {
        return state.ramadan.filter;
      },
      listKota: (state) => {
        return state.ramadan.kota.items;
      },
      oneKota: (state) => {
        return state.ramadan.one_kota.items;
      },
      listJadwal: (state) => {
        return state.ramadan.jadwal;
      },
      listJadwalBulanan: (state) => {
        return state.ramadan.jadwalBulanan;
      },
      tags: (state) => {
        return state.videos.tags;
      },
    }),
  },
  methods: {
    changedLabel(e) {
      //   this.$emit("option:selected", selectedOption);
      console.log(e, "hoi");
    },
    async initData() {
      await this.$store
        .dispatch("ramadan/getOneKota", this.$route.params.slug)
        .then(() => {
          this.kota = {
            provKode: this.oneKota.provKode,
            provNama: this.oneKota.provNama,
            kabkoKode: this.oneKota.kabkoKode,
            kabkoNama: this.oneKota.kabkoNama,
            slug: this.oneKota.slug,
          };
          this.$store.dispatch("ramadan/getJadwal", {
            tahun: this.filter.tahun,
            bulan: this.filter.bulan,
            provinsi: this.oneKota.provKode,
            kota: this.oneKota.kabkoKode,
            date: new Date(),
          });
        });
      await this.$store.dispatch("ramadan/getKota");
      this.bulan = {
        label: this.$moment()
          .month(this.filter.bulan - 1)
          .format("MMMM"),
        value: this.filter.bulan,
      };
      this.tahun = this.filter.tahun;
    },
    filterJadwal() {
      this.$store.dispatch("ramadan/getJadwal", {
        tahun: this.filter.tahun,
        bulan: this.filter.bulan,
        provinsi: this.oneKota.provKode,
        kota: this.oneKota.kabkoKode,
        date: new Date(),
      });
    },
    capitalize(param) {
      const mySentence = param.toLowerCase();
      const words = mySentence.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      return words.join(" ");
    },
    setLink(param) {
      switch (param) {
        case "whatsapp":
          return (
            "https://wa.me/?text=" +
            this.url +
            "%3Futm_source=whatsapp%26utm_medium=share"
          );
        case "facebook":
          return (
            "https://www.facebook.com/sharer.php?u=" +
            this.url +
            "?utm_source=facebook&utm_medium=share"
          );
        case "twitter":
          return (
            "https://twitter.com/intent/tweet?url=" +
            this.url +
            "?utm_source=twitter&utm_medium=share"
          );
      }
    },
    copyLink() {
      navigator.clipboard.writeText(
        this.url + "?utm_source=copy_link&utm_medium=share"
      );
      this.$toast.success("Copy Link Success");
    },
  },
  async fetch() {
    await this.initData();
  },
  mounted() {
    this.url = window.location.href;
    this.$store.dispatch("videos/getTags", {
      tagsSlug: "ramadan-dan-religi",
      page: this.page,
      firstLoad: true,
    });
    this.$store.dispatch("articles/getArticlesByTags", {
      tagsSlug: "pesantrenkilat,belajardarihati",
      page: 1,
    });
  },
};
</script>

<style lang="scss" scoped>
.ads-props {
  margin-top: 32px;
}
.ramadan {
  .peskil {
    background-color: #ffffff;
    @media only screen and (max-width: 1024px) {
      top: 124px;
    }
    ::v-deep .pesantren-kilat {
      @media only screen and (max-width: 1024px) {
        margin-top: 0;
      }
      .container {
        .content {
          border: 1px solid #c9c9c9;
          border-radius: 8px;
        }
      }
    }
  }
  .banner {
    position: relative;
    .banner-ramadan {
      width: 100%;
    }
    .headline {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: fit-content;
      @media only screen and (max-width: 1024px) {
        padding: 16px 0;
        top: 40%;
      }
      .container {
        .title-wrapper {
          background: rgba(245, 246, 250, 0.75);
          backdrop-filter: blur(2px);
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: fit-content;
          margin: 0 auto;
          padding: 12px 32px 12px 32px;
          border-radius: 8px;
          @media only screen and (max-width: 1024px) {
            text-align: center;
            gap: 0;
            width: 303px;
          }
          .title {
            font-weight: 800;
            font-size: 36px;
            font-family: "NarasiSans", "sans-serif";
            color: #1b5482;
            margin: 0;
            @media only screen and (max-width: 1024px) {
              font-size: 16px;
            }
          }
          .city {
            font-family: "NarasiSans", "sans-serif";
            font-size: 24px;
            font-weight: 500;
            color: #1b5482;
            text-transform: lowercase;
            &::first-line {
              text-transform: capitalize;
            }
            @media only screen and (max-width: 1024px) {
              font-size: 16px;
              font-weight: 800;
            }
          }
          .sub-title {
            font-size: 12px;
            color: #1b5482;
            @media only screen and (max-width: 1024px) {
              font-size: 7px;
            }
          }
        }
      }
    }
  }
  .quran-dan-jadwal {
    position: relative;
    padding-bottom: 20px;
    margin-top: -7px;
    background: #1b5482;
    @media only screen and (max-width: 1024px) {
      margin-top: -50px;
    }
    .container {
      .schedule {
        display: flex;
        gap: 20px;
        @media only screen and (max-width: 1024px) {
          flex-direction: column-reverse;
          gap: 0;
        }
        .quran {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 20%;
          background: #f5f6fa;
          border-radius: 8px;
          overflow: hidden;
          .image {
            height: auto;
          }
          .title {
            display: flex;
            padding: 16px 16px 0;
            align-items: center;
            svg {
              margin-right: 10px;
            }
            p {
              font-size: 16px;
              font-weight: 500;
              margin: 0;
            }
          }
          .desc {
            padding: 16px 16px 0;
            font-size: 12px;
            color: #616161;
            margin-bottom: 30px;
          }
          .quran-btn {
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            @media only screen and (max-width: 1024px) {
              //   justify-content: space-between;
              //   padding: 8px 16px;
              display: none;
            }
            p {
              font-size: 14px;
              font-weight: 500;
              color: #1b5482;
              margin: 0;
            }
            svg {
              width: 24px;
              height: auto;
            }
          }
        }
        .jadwal-sholat {
          display: flex;
          flex-direction: column;
          flex: 80%;
          justify-content: space-between;
          gap: 20px;
          @media only screen and (max-width: 1024px) {
            flex-direction: column;
          }
          .jadwal {
            background: #f5f6fa;
            border-radius: 8px;
            flex-grow: 1;
            padding: 16px;
            display: flex;
            align-items: center;
            @media only screen and (max-width: 1024px) {
              padding: 0;
              background: none;
            }
            .list-jadwal-sholat {
              display: flex;
              justify-content: space-between;
              gap: 20px;
              width: 100%;
              @media only screen and (max-width: 1024px) {
                flex-direction: column;
                gap: 0;
              }
              .shimmer-box-wrapper {
                flex-grow: 1;
                border-radius: 8px;
              }
              .box-wrapper {
                background: #ffffff;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                flex-grow: 1;
                @media only screen and (max-width: 1024px) {
                  padding: 8px;
                  border-radius: 0;
                }
                &:last-child {
                  @media only screen and (max-width: 1024px) {
                    border-radius: 0 0 8px 8px;
                  }
                }
                &:first-child {
                  @media only screen and (max-width: 1024px) {
                    border-radius: 8px 8px 0 0;
                  }
                }
                .box {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  padding: 16px;
                  @media only screen and (max-width: 1024px) {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 8px;
                  }
                  .waktu-sholat {
                    font-size: 18px;
                    font-weight: 500;
                    color: #202020;
                    margin: 0;
                    text-transform: capitalize;
                    @media only screen and (max-width: 1024px) {
                      font-size: 14px;
                    }
                  }
                  .jam-sholat {
                    font-size: 14px;
                    color: #202020;
                    margin: 0;
                    @media only screen and (max-width: 1024px) {
                      font-size: 14px;
                    }
                  }
                }
                &.active {
                  .box {
                    background: #1b5482;
                    border-radius: 8px;
                    .waktu-sholat {
                      color: #fff;
                    }
                    .jam-sholat {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
          .filter-jadwal {
            display: flex;
            gap: 16px;
            background: #f5f6fa;
            border-radius: 8px;
            padding: 16px;
            @media only screen and (max-width: 1024px) {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              gap: 8px;
              padding: 0;
              background: none;
            }
            .copy-wrapper {
              flex: 30%;
              .title {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 16px;
              }
              .desc {
                font-size: 12px;
                color: #616161;
                margin: 0;
              }
            }
            .select-box {
              flex: 70%;
              display: flex;
              flex-wrap: wrap;
              gap: 8px;
              .v-select {
                flex: 48%;
                border-radius: 4px;
                height: fit-content;
                &.select-kota {
                  flex: 100%;
                }
                &.select-bulan {
                  flex: 30%;
                  @media only screen and (max-width: 1024px) {
                    flex: 48%;
                  }
                }
                &.select-tahun {
                  flex: 20%;
                  @media only screen and (max-width: 1024px) {
                    flex: 48%;
                  }
                }
                ::v-deep {
                  input {
                    @media only screen and (max-width: 1024px) {
                      font-size: 12px;
                    }
                  }
                  ul {
                    li {
                      &:hover {
                        background: #8166c8;
                        color: #ffe900;
                      }
                    }
                  }
                }
              }
              .btn-box {
                display: flex;
                flex-direction: column;
                gap: 8px;
                flex: auto;
                height: 100%;
                @media only screen and (max-width: 1024px) {
                  flex: 100%;
                }
                .btn-filter {
                  display: flex;
                  flex: 50%;
                  justify-content: center;
                  align-items: center;
                  padding: 0 20px;
                  border-radius: 4px;
                  font-size: 16px;
                  font-weight: 500;
                  color: #ffe900;
                  background: #2e95b7;
                  cursor: pointer;
                  @media only screen and (max-width: 1024px) {
                    font-size: 12px;
                    padding: 8px;
                    justify-content: center;
                    flex: 100%;
                  }
                  &:hover {
                    color: #ffe900 !important;
                  }
                  &.disable {
                    pointer-events: none;
                    filter: grayscale(1);
                  }
                }
                div {
                  flex: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
  .content {
    background: #1b5482;
    padding-bottom: 48px;
    @media only screen and (max-width: 1024px) {
      padding-bottom: 32px;
    }
    .container {
      display: flex;
      gap: 20px;
      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 0;
      }
      .jadwal-bulanan {
        flex: 60%;
        @media only screen and (max-width: 1024px) {
          flex: 100%;
          overflow: auto;
        }
        table {
          position: relative;
          width: 100%;
          text-align: center;
          color: #ffffff;
          @media only screen and (max-width: 1024px) {
            table-layout: fixed;
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
          }
          .box {
            &.desktop {
              display: table-cell;
              @media only screen and (max-width: 1024px) {
                display: none;
              }
            }
            &.mobile {
              display: none;
              @media only screen and (max-width: 1024px) {
                display: table-cell;
              }
            }
          }
          tr {
            position: relative;
            &:nth-child(even) {
              th,
              td {
                background-color: #0095b8;
              }
            }
            th,
            td {
              font-size: 14px;
              font-weight: 500;
              padding: 12px 0;
              background: #1b5482;
              @media only screen and (max-width: 1024px) {
                width: 100px;
                font-size: 12px;
                white-space: nowrap;
              }
              &:nth-child(2) {
                @media only screen and (max-width: 1024px) {
                  position: sticky;
                  left: 0;
                  z-index: 2;
                  box-shadow: 10px 10px 10px rgb(0 0 0 / 30%);
                }
              }
            }
            th {
              position: relative;
              color: #ffe900;
              &:first-child {
                color: #ffffff;
              }
              &:nth-child(2) {
                @media only screen and (max-width: 1024px) {
                  color: #ffffff;
                }
              }
            }

            td {
              &:nth-child(3) {
                @media only screen and (min-width: 1024px) {
                  color: #ff8a00;
                }
              }
              &:nth-child(7) {
                @media only screen and (min-width: 1024px) {
                  color: #ff8a00;
                }
              }
              &:nth-child(3) {
                @media only screen and (max-width: 1024px) {
                  color: #ff8a00;
                }
              }
              &:nth-child(7) {
                @media only screen and (max-width: 1024px) {
                  color: #ff8a00;
                }
              }
            }
            &.active {
              th,
              td {
                background-color: #bcac01;
                color: #ffffff;
              }
            }
          }
        }

        .btn-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 24px 0;
          a {
            font-size: 14px;
            color: #ffe900;
            display: flex;
            align-items: center;
            &:hover {
              color: #ffe900 !important;
            }
            svg {
              width: 30px;
              height: auto;
              display: inline-block;
            }
          }
          .share-wrapper {
            display: flex;
            align-items: center;
            cursor: pointer;
            .sosmed-wrapper {
              svg {
                width: 32px;
                height: 32px;
                margin-left: 8px;
                @media only screen and (max-width: 1024px) {
                  width: 24px;
                  height: 24px;
                  margin-right: 10px;
                  margin-left: unset;
                }
              }
            }
          }
        }
      }
      .btn-wrapper {
        @media only screen and (max-width: 1024px) {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.75rem;
        }
        a {
          @media only screen and (max-width: 1024px) {
            font-size: 11px;
            color: #ffe900;
          }
          &:hover {
            color: #ffe900 !important;
          }
          svg {
            width: 20px;
            height: auto;
          }
        }

        .share-wrapper {
          display: flex;
          align-items: center;
          cursor: pointer;
          .sosmed-wrapper {
            svg {
              width: 32px;
              height: 32px;
              margin-left: 8px;
              @media only screen and (max-width: 1024px) {
                width: 24px;
                height: 24px;
                margin-right: 10px;
                margin-left: unset;
              }
            }
          }
        }
      }
      .quran-btn-mobile {
        display: none;
        @media only screen and (max-width: 1024px) {
          display: block;
          padding: 0 0.75rem;
        }
        .container {
          @media only screen and (max-width: 1024px) {
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #ffffff;
            cursor: pointer;
            justify-content: space-between;
            padding: 8px 16px;
          }
          p {
            @media only screen and (max-width: 1024px) {
              font-size: 14px;
              font-weight: 500;
              color: #1b5482;
              margin: 0;
            }
          }
          svg {
            width: 24px;
            height: auto;
          }
        }
      }
      .narasi-ramadan {
        flex: 30%;
        @media only screen and (max-width: 1024px) {
          margin-top: 24px;
        }
        .container {
          display: flex;
          flex-direction: column;

          @media only screen and (max-width: 1024px) {
            padding: 0 0.75rem;
          }
          .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }

          .lihat-semua {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #ffe900;
            text-decoration: underline !important;
            &:hover {
              color: #ffe900 !important;
            }
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }

          .article-box {
            flex: 70%;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 0;
            grid-row-gap: 10px;
            @media only screen and (max-width: 1024px) {
              grid-template-columns: repeat(1, 1fr);
              grid-row-gap: 10px;
            }
            &.fitContent {
              height: fit-content;
            }
            .card-narasi-ramadan {
              width: 100%;
              height: fit-content;
              display: flex;
              flex-direction: row;
              flex: 30%;
              gap: 16px;
              padding-bottom: 10px;
              border-bottom: 1px solid #614f90;
              @media only screen and (max-width: 1024px) {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
              }
              &:last-child {
                border: none;
                padding: 0;
              }
              .card-narasi-ramadan-thumbnail {
                position: relative;
                // border-radius: 10px;
                overflow: hidden;
                flex: none;
                width: 5vw;
                height: 5vw;
                @media only screen and (max-width: 1024px) {
                  // border-radius: 6px;
                  width: 25vw;
                  height: 25vw;
                }
                .card-narasi-ramadan-image {
                  width: 100%;
                  height: 100%;
                  ::v-deep img {
                    object-fit: cover;
                  }
                }
                .card-narasi-ramadan-duration {
                  background: rgba(0, 0, 0, 0.7);
                  padding: 3px 6px;
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  border-top-left-radius: 10px;
                  @media only screen and (max-width: 1024px) {
                    border-top-left-radius: 6px;
                  }
                  &.backgroundAds {
                    background: rgba(128, 128, 128, 0.7);
                    padding: 3px 8px;
                  }
                  .advertorial {
                    font-size: 12px;
                    font-weight: 500;
                    color: #ffffff;
                    margin: 0;
                    @media only screen and (max-width: 1024px) {
                      font-size: 10px;
                    }
                  }
                  .article-reguler {
                    display: flex;
                    align-items: center;
                    .bi-book-half {
                      width: 16px;
                      height: auto;
                      margin-right: 2px;
                      color: #ffffff;
                    }
                    .text {
                      font-size: 10px;
                      font-weight: 500;
                      color: #ffffff;
                      margin: 0;
                    }
                  }
                  .duration {
                    font-size: 12px;
                    font-weight: 500;
                    color: #ffffff;
                    margin: 0;
                    @media only screen and (max-width: 1024px) {
                      font-size: 10px;
                    }
                  }
                }
              }
              .card-narasi-ramadan-body {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: auto;
                @media only screen and (max-width: 1024px) {
                  height: 25vw;
                  justify-content: unset;
                  flex: inherit;
                }
                .card-narasi-ramadan-body-header {
                  .card-narasi-ramadan-channel-category {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    .channel,
                    .category {
                      font-size: 12px;
                      font-weight: 400;
                      color: #ffe900;
                      margin: 0;
                      &:hover {
                        color: #ffe900 !important;
                      }
                      &.small {
                        @media only screen and (max-width: 1024px) {
                          font-size: 10px;
                        }
                      }
                    }
                    span {
                      font-size: 12px;
                      padding: 0 10px;
                      color: #ffe900;
                      @media only screen and (max-width: 1024px) {
                        font-size: 10px;
                      }
                      &.small {
                        @media only screen and (max-width: 1024px) {
                          font-size: 8px;
                          padding: 0 5px;
                        }
                      }
                    }
                  }
                  .card-narasi-ramadan-title {
                    font-size: 16px;
                    font-weight: 500;
                    color: #f1f1f1;
                    margin-bottom: 8px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    @media only screen and (max-width: 1024px) {
                      font-size: 14px;
                      line-height: 18px;
                      -webkit-line-clamp: 4;
                    }
                  }
                }
                .card-narasi-ramadan-body-footer {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  .category {
                    display: none;
                    @media only screen and (max-width: 1024px) {
                      display: block;
                      font-size: 12px;
                      font-weight: 400;
                      color: #ffe900;
                      margin: 0;
                      padding-right: 5px;
                      &.small {
                        font-size: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
