var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ramadan"},[_c('div',{staticClass:"peskil"}),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('section',{staticClass:"surat"},[_c('ReligiHorizontalAds'),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"filter-surat"},[_c('Icons',{staticClass:"search-icon",attrs:{"name":"search"}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"type":"text","name":"","placeholder":"Ketik Surat yang ingin dibaca"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})],1),_vm._v(" "),_c('div',{staticClass:"box-surat"},[_c('div',{staticClass:"list-surat"},_vm._l((_vm.listSurat.items.filter(
              (key) =>
                key.surat_name.includes(_vm.search) ||
                key.surat_name.toLowerCase().includes(_vm.search)
            )),function(item){return _c('Link',{key:item.id,staticClass:"box-wrapper",attrs:{"to":{
              name: 'religi-quran-digital-slug',
              params: {
                slug: item.slug,
              },
            }}},[_c('div',{staticClass:"nomor-surat"},[_c('Icons',{attrs:{"name":"surat-icon"}}),_vm._v(" "),_c('p',{staticClass:"nomor"},[_vm._v(_vm._s(item.id))])],1),_vm._v(" "),_c('div',{staticClass:"nama-surat"},[_c('p',{staticClass:"arabic"},[_vm._v(_vm._s(item.surat_name))]),_vm._v(" "),_c('p',{staticClass:"indonesia"},[_vm._v(_vm._s(item.surat_terjemahan))])]),_vm._v(" "),_c('div',{staticClass:"nama-surat-arabic"},[_vm._v(_vm._s(item.surat_text))])])}),1)]),_vm._v(" "),_c('div',{staticClass:"ads-props",attrs:{"id":"M857969ScriptRootC1408001"}}),_vm._v(" "),_c('div',{staticClass:"share"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"share-wrapper"},[_c('hr'),_vm._v(" "),_c('a',{staticClass:"sosmed-wrapper share-artikel",attrs:{"href":_vm.setLink('whatsapp'),"target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"name":"whatsapp"}})],1),_vm._v(" "),_c('a',{staticClass:"sosmed-wrapper share-artikel",attrs:{"href":_vm.setLink('twitter'),"target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"name":"twitter-new"}})],1),_vm._v(" "),_c('a',{staticClass:"sosmed-wrapper share-artikel",attrs:{"href":_vm.setLink('facebook'),"target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"name":"facebook"}})],1),_vm._v(" "),_c('div',{staticClass:"sosmed-wrapper share-artikel",on:{"click":function($event){return _vm.copyLink()}}},[_c('Icons',{attrs:{"name":"copy-link"}})],1),_vm._v(" "),_c('hr')])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banner"},[_c('img',{staticClass:"banner-ramadan is-desktop",attrs:{"src":"/storage/images/ramadan/bg-desktop.webp","alt":""}}),_vm._v(" "),_c('img',{staticClass:"banner-ramadan is-mobile",attrs:{"src":"/storage/images/ramadan/bg-mobile.png","alt":""}}),_vm._v(" "),_c('div',{staticClass:"headline"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title-wrapper"},[_c('h1',{staticClass:"title"},[_vm._v("Al-Qur’an Digital")]),_vm._v(" "),_c('span',{staticClass:"sub-title"},[_vm._v("\n            SUMBER: LAJNAH PENTASHIHAN MUSHAF AL-QUR’AN, KEMENTERIAN AGAMA RI\n          ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('hr'),_vm._v(" "),_c('p',[_vm._v("Bagikan")]),_vm._v(" "),_c('hr')])
}]

export { render, staticRenderFns }