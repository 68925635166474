var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ramadan"},[_c('div',{staticClass:"peskil"}),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('ReligiHorizontalAds'),_vm._v(" "),_c('section',{staticClass:"artikel"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"box-artikel"},[_c('div',{staticClass:"list-artikel"},_vm._l((_vm.tags.items),function(item){return _c('Link',{key:item.id,staticClass:"box-wrapper",attrs:{"to":{
              name: item.isText ? 'read-channel-slug' : 'video-channel-slug',
              params: {
                channel: item.channel.slug,
                slug: item.slug,
              },
              query: {
                ref: 'artikel-ramadan',
              },
            }}},[_c('ImageResponsive',{staticClass:"card-narasi-ramadan-image",attrs:{"imageUrl":item.thumbnail && item.thumbnail.medium
                  ? item.thumbnail.medium
                  : 'https://stg.narasi.co.id/storage/images/dummy.png',"fromUrl":true,"width":"324","height":"181","widthMobile":"100","heightMobile":"100"}}),_vm._v(" "),_c('div',{staticClass:"copy-wrapper"},[_c('div',{staticClass:"card-narasi-ramadan-channel-category"},[(item && item.channel)?_c('Link',{staticClass:"channel",attrs:{"to":{
                    name: 'program-childBrand-slugProgram',
                    params: {
                      childBrand: item.channel.program.slug,
                      slugProgram: item.channel.slug,
                    },
                    query: {
                      type: 'highlights',
                    },
                  }}},[_vm._v(_vm._s(item.channel.title))]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"card-narasi-ramadan-title"},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")])])],1)}),1),_vm._v(" "),(_vm.tags.items.length > 0 && _vm.tags.pages > 1)?_c('div',{staticClass:"pagination-cards"},[(_vm.tags && _vm.tags.pages > 1)?_c('paginate',{attrs:{"page-count":_vm.tags.pages,"page-range":3,"margin-pages":1,"prev-class":"arrow","next-class":"arrow","click-handler":_vm.clickCallback,"container-class":'pagination',"page-class":'page-item'},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_c('template',{slot:"prevContent"},[_c('Icons',{attrs:{"name":"arrow-left","color":"#757575"}})],1),_vm._v(" "),_c('template',{slot:"nextContent"},[_c('Icons',{attrs:{"name":"arrow-right","color":"#757575"}})],1)],2):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"ads-props",attrs:{"id":"M857969ScriptRootC1408001"}})])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banner"},[_c('img',{staticClass:"banner-ramadan is-desktop",attrs:{"src":"/storage/images/ramadan/bg-desktop.webp","alt":""}}),_vm._v(" "),_c('img',{staticClass:"banner-ramadan is-mobile",attrs:{"src":"/storage/images/ramadan/bg-mobile.png","alt":""}}),_vm._v(" "),_c('div',{staticClass:"headline"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title-wrapper"},[_c('h2',{staticClass:"title"},[_vm._v("Artikel Ramadan")])])])])])
}]

export { render, staticRenderFns }