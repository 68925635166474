<template>
  <div class="ramadan">
    <div class="peskil">
      <!-- <PesantrenKilat :articles="$store.state.articles.articles_tags.items" /> -->
    </div>
    <section class="banner">
      <img
        src="/storage/images/ramadan/bg-desktop.webp"
        class="banner-ramadan is-desktop"
        alt=""
      />
      <img
        src="/storage/images/ramadan/bg-mobile.png"
        class="banner-ramadan is-mobile"
        alt=""
      />
      <div class="headline">
        <div class="container">
          <div class="title-wrapper">
            <h1 class="title">Al-Qur’an Digital</h1>
            <span class="sub-title">
              SUMBER: LAJNAH PENTASHIHAN MUSHAF AL-QUR’AN, KEMENTERIAN AGAMA RI
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="surat">
      <!-- START ADS -->
      <ReligiHorizontalAds />
      <!-- ADS END -->
      <div class="container">
        <div class="filter-surat">
          <Icons name="search" class="search-icon" />
          <input
            type="text"
            name=""
            v-model="search"
            placeholder="Ketik Surat yang ingin dibaca"
          />
        </div>
        <div class="box-surat">
          <!-- <h2>Daftar Surat</h2> -->
          <div class="list-surat">
            <Link
              v-for="item in listSurat.items.filter(
                (key) =>
                  key.surat_name.includes(search) ||
                  key.surat_name.toLowerCase().includes(search)
              )"
              :key="item.id"
              :to="{
                name: 'religi-quran-digital-slug',
                params: {
                  slug: item.slug,
                },
              }"
              class="box-wrapper"
            >
              <div class="nomor-surat">
                <Icons name="surat-icon" />
                <p class="nomor">{{ item.id }}</p>
              </div>
              <div class="nama-surat">
                <p class="arabic">{{ item.surat_name }}</p>
                <p class="indonesia">{{ item.surat_terjemahan }}</p>
              </div>
              <div class="nama-surat-arabic">{{ item.surat_text }}</div>
            </Link>
          </div>
        </div>
        <!-- ADS PROPS START -->
        <div id="M857969ScriptRootC1408001" class="ads-props"></div>
        <!-- ADS PROPS END -->
        <div class="share">
          <div class="wrapper">
            <hr />
            <p>Bagikan</p>
            <hr />
          </div>
          <div class="share-wrapper">
            <hr />
            <a
              :href="setLink('whatsapp')"
              target="_blank"
              rel="noopener"
              class="sosmed-wrapper share-artikel"
            >
              <Icons name="whatsapp" />
            </a>
            <a
              :href="setLink('twitter')"
              target="_blank"
              rel="noopener"
              class="sosmed-wrapper share-artikel"
            >
              <Icons name="twitter-new" />
            </a>
            <a
              :href="setLink('facebook')"
              target="_blank"
              rel="noopener"
              class="sosmed-wrapper share-artikel"
            >
              <Icons name="facebook" />
            </a>
            <div class="sosmed-wrapper share-artikel" @click="copyLink()">
              <Icons name="copy-link" />
            </div>
            <hr />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import config from "../../../config";
import { mapGetters, mapState } from "vuex";
import Icons from "@/components/Icons";
import Link from "@/components/Link";
import PesantrenKilat from "@/components/ramadan/PesantrenKilat.vue";
import ReligiHorizontalAds from "@/components/ads/religi/Horizontal.vue";

export default {
  components: {
    Icons,
    Link,
    PesantrenKilat,
    ReligiHorizontalAds,
  },
  async asyncData({ error, store, route, app, redirect }) {
    try {
      store.commit("seo/SET_SEO", {
        title: `Al-Quran Online dengan Arab, Terjemahan Bahasa Indonesia, Latin dan Tafsir Ayat`,
        desc: `Baca Al-Quran Online 30 Juz dengan Terjemahan Bahasa Indonesia, Latin, Tanda Waqaf & Tafsir Ayat dengan lengkap, mudah dan praktis hanya di Narasi.`,
        image: "https://narasi.tv/icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  data() {
    return {
      search: "",
      url: "",
    };
  },
  watch: {},
  head() {
    return {
      ...this.headReturn,
      script: [{ src: "https://jsc.mgid.com/p/r/props.narasi.tv.1408001.js" }],
    };
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      listSurat: (state) => {
        return state.ramadan.surat;
      },
    }),
  },
  methods: {
    setLink(param) {
      switch (param) {
        case "whatsapp":
          return (
            "https://wa.me/?text=" +
            this.url +
            "%3Futm_source=whatsapp%26utm_medium=share"
          );
        case "facebook":
          return (
            "https://www.facebook.com/sharer.php?u=" +
            this.url +
            "?utm_source=facebook&utm_medium=share"
          );
        case "twitter":
          return (
            "https://twitter.com/intent/tweet?url=" +
            this.url +
            "?utm_source=twitter&utm_medium=share"
          );
      }
    },
    copyLink() {
      navigator.clipboard.writeText(
        this.url + "?utm_source=copy_link&utm_medium=share"
      );
      this.$toast.success("Copy Link Success");
    },
  },
  mounted() {
    this.url = window.location.href;
    this.$store.dispatch("ramadan/getSurat");
    this.$store.dispatch("articles/getArticlesByTags", {
      tagsSlug: "pesantrenkilat,belajardarihati",
      page: 1,
    });
  },
};
</script>

<style lang="scss" scoped>
.ads-props {
  margin-top: 32px;
}
.ramadan {
  .peskil {
    background-color: #ffffff;
    @media only screen and (max-width: 1024px) {
      top: 124px;
    }
    ::v-deep .pesantren-kilat {
      @media only screen and (max-width: 1024px) {
        margin-top: 0;
      }
      .container {
        .content {
          border: 1px solid #c9c9c9;
          border-radius: 8px;
        }
      }
    }
  }
  .banner {
    position: relative;
    .banner-ramadan {
      width: 100%;
    }
    .headline {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: fit-content;
      @media only screen and (max-width: 1024px) {
        top: 8%;
        left: 2%;
        transform: unset;
      }
      .container {
        .title-wrapper {
          background: rgba(245, 246, 250, 0.75);
          backdrop-filter: blur(2px);
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: fit-content;
          margin: 0 auto;
          padding: 12px 32px 12px 32px;
          border-radius: 8px;
          @media only screen and (max-width: 1024px) {
            text-align: left;
          }
          .title {
            font-weight: 800;
            font-size: 36px;
            font-family: "NarasiSans", "sans-serif";
            color: #1b5482;
            margin: 0;
            @media only screen and (max-width: 1024px) {
              font-size: 18px;
              margin-bottom: 4px;
            }
          }
          .sub-title {
            font-size: 12px;
            color: #1b5482;
            @media only screen and (max-width: 1024px) {
              font-size: 7px;
            }
          }
        }
      }
    }
  }
  .surat {
    background-color: #1b5482;
    padding-bottom: 48px;
    @media only screen and (max-width: 1024px) {
      padding-bottom: 32px;
      margin-top: -50px;
      z-index: 2;
      position: relative;
    }
    .container {
      .filter-surat {
        background: #ffffff;
        display: inline-flex;
        gap: 8px;
        padding: 10px 20px;
        margin-top: 20px;
        border-radius: 50rem;
        align-items: center;
        width: 100%;
        @media only screen and (max-width: 1024px) {
          padding: 6px 16px;
        }
        svg {
          width: 20px;
          height: auto;
          @media only screen and (max-width: 1024px) {
            width: 16px;
          }
        }
        input {
          width: 300px;
          font-size: 18px;
          border: none;
          padding: 0 8px;
          flex: auto;
          @media only screen and (max-width: 1024px) {
            flex: auto;
            font-size: 14px;
            padding: 4px 8px;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .box-surat {
        background: #f5f6fa;
        padding: 24px 22px;
        margin-top: 24px;
        border-radius: 8px;
        h2 {
          text-align: center;
          margin-bottom: 24px;
        }
        .list-surat {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(1, 1fr);
          grid-row-gap: 16px;
          grid-column-gap: 16px;
          @media only screen and (max-width: 1024px) {
            grid-template-columns: repeat(1, 1fr);
          }
          .box-wrapper {
            padding: 24px;
            background: #ffffff;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            @media only screen and (max-width: 1024px) {
              gap: 8px;
              box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
              padding: 16px;
            }
            .nomor-surat {
              position: relative;
              flex: inherit;
              svg {
                width: 45px;
                height: auto;
                display: block;
                @media only screen and (max-width: 1024px) {
                  width: 40px;
                }
              }
              .nomor {
                font-size: 16px;
                color: #1b5482;
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @media only screen and (max-width: 1024px) {
                  font-size: 14px;
                }
              }
            }
            .nama-surat {
              display: flex;
              flex-direction: column;
              flex: auto;
              .arabic {
                font-size: 18px;
                color: #202020;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  font-size: 14px;
                }
              }
              .indonesia {
                font-size: 18px;
                color: #868686;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  font-size: 14px;
                }
              }
            }
            .nama-surat-arabic {
              flex: auto;
              text-align: right;
              font-size: 28px;
              font-family: "Lpmq", "Roboto";
              color: #000000;
              @media only screen and (max-width: 1024px) {
                font-size: 20px;
              }
            }
          }
        }
      }
      .share {
        margin-top: 24px;
        .wrapper {
          display: flex;
          align-items: center;
          gap: 16px;
          hr {
            flex: auto;
            color: #d9d9d9;
          }
          p {
            font-size: 16px;
            font-weight: 500;
            color: #f1f1f1;
            margin: 0;
            @media only screen and (max-width: 1024px) {
              font-size: 14px;
            }
          }
        }
        .share-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 16px;
          cursor: pointer;
          @media only screen and (max-width: 1024px) {
            margin-top: 0;
          }
          .sosmed-wrapper {
            svg {
              width: 32px;
              height: 32px;
              margin-left: 8px;
              @media only screen and (max-width: 1024px) {
                width: 24px;
                height: 24px;
                margin-right: 10px;
                margin-left: unset;
              }
            }
          }
        }
      }
    }
  }
}
</style>
