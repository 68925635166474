<template>
  <div class="ramadan">
    <div class="peskil">
      <!-- <PesantrenKilat :articles="$store.state.articles.articles_tags.items" /> -->
    </div>
    <section class="banner">
      <img
        src="storage/images/ramadan/bg-desktop.webp"
        class="banner-ramadan is-desktop"
      />
      <img
        src="storage/images/ramadan/bg-mobile.png"
        class="banner-ramadan is-mobile"
      />
      <div class="headline">
        <div class="container">
          <div class="title-wrapper">
            <h1 class="title">Jadwal Sholat dan Imsakiyah</h1>
            <span class="city">untuk Kota Jakarta dan Sekitarnya Hari Ini</span>
            <span class="sub-title">SUMBER: KEMENTERIAN AGAMA RI</span>
          </div>
        </div>
      </div>
    </section>
    <section class="quran-dan-jadwal">
      <div class="container">
        <div class="schedule">
          <div class="quran">
            <ImageResponsive imageUrl="ramadan/quran.png" class="is-desktop" />
            <div class="title is-desktop-flex">
              <Icons name="quran-icon" color />
              <p>Quran Digital</p>
            </div>
            <div class="desc is-desktop">
              Baca, pelajari, dan pahami Quran dengan memanfaatkan fitur audio
              bacaan, terjemahan, beserta tafsirnya di sini.
            </div>
            <Link :to="{ name: 'religi-quran-digital' }" class="quran-btn">
              <Icons name="quran-icon" class="is-mobile" />
              <p>Mulai baca Qur’an</p>
              <Icons name="arrow-right" />
            </Link>
          </div>
          <div class="pilih-jadwal">
            <ImageResponsive imageUrl="ramadan/mekah.png" class="is-desktop" />
            <div class="title is-desktop-flex">
              <Icons name="clock" />
              <p>Jadwal Sholat</p>
            </div>
            <div class="desc is-desktop">
              Bagi Anda yang tinggal di KOTA JAKARTA, simpan dan bagikan halaman
              ini untuk mengetahui jadwal shalat 5 waktu di kota Anda. Berikut
              ini jadwal shalat di KOTA JAKARTA :
            </div>
            <div class="pilih-kota is-desktop">
              <v-select
                v-model="kota"
                placeholder="Pilih kota"
                label="kabkoNama"
                :options="listKota"
              ></v-select>
            </div>
          </div>
          <div class="jadwal-sholat">
            <div class="jadwal">
              <div class="pilih-kota is-mobile">
                <v-select
                  v-model="kota"
                  placeholder="Pilih kota"
                  label="kabkoNama"
                  :options="listKota"
                ></v-select>
              </div>
              <div class="date">
                <Icons
                  name="arrow-left"
                  class="prev-day"
                  @click.native="goToDate('minus')"
                />
                <date-picker
                  class="date-picker"
                  v-model="date"
                  :masks="masks"
                  locale="in"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div class="selected-date" @click="togglePopover()">
                      <div locale="in" class="value-date">
                        {{ inputValue }}
                      </div>
                      <Icons name="calendar-dots" class="calendar-icon" />
                    </div>
                  </template>
                </date-picker>
                <Icons
                  name="arrow-right"
                  class="next-day"
                  @click.native="goToDate()"
                />
              </div>
              <div class="list-jadwal-sholat">
                <div
                  v-for="(item, key, index) in listJadwal.items"
                  :key="index"
                  class="box-wrapper"
                  :class="{ active: key == 'imsak' || key == 'maghrib' }"
                >
                  <div class="box">
                    <p class="waktu-sholat">{{ key }}</p>
                    <p class="jam-sholat">{{ item }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReligiHorizontalAds />
    </section>
    <section
      class="shihab-shihab"
      v-show="shihabShihab.items && shihabShihab.items.length > 0"
    >
      <div class="container">
        <div class="title-wrapper">
          <TitleSection title="SHIHAB & SHIHAB" color="white" />
          <Link
            :to="{
              name: 'tags-slug',
              params: {
                slug: 'shihab-shihab',
              },
            }"
            class="lihat-semua"
            >Lihat Semua
          </Link>
        </div>
        <div class="list-article-shihab-shihab">
          <div v-swiper:mySwiperVideoShihab="swiperOptions.videoShihab">
            <div
              class="swiper-wrapper overflow-hidden"
              v-if="shihabShihab.loading"
            >
              <ShimmerCardRamadan
                v-for="(item, index) in 4"
                :key="index"
                class="swiper-slide"
              />
            </div>
            <div class="swiper-wrapper" v-else>
              <Link
                v-for="item in shihabShihab.items"
                :key="item.id"
                :to="{
                  name: item.isText
                    ? 'read-channel-slug'
                    : 'video-channel-slug',
                  params: {
                    channel: item.channel ? item.channel.slug : '',
                    slug: item.slug,
                  },
                }"
                class="card-shihab swiper-slide"
              >
                <div class="thumbnail">
                  <ImageResponsive
                    :imageUrl="
                      item.thumbnail && item.thumbnail.medium
                        ? item.thumbnail.medium
                        : 'https://stg.narasi.co.id/storage/images/dummy.png'
                    "
                    :fromUrl="true"
                    class="image"
                  />
                  <div
                    class="duration"
                    :class="{ backgroundAds: item.isAdvertorial }"
                  >
                    <p class="advertorial" v-if="item.isAdvertorial">Ad</p>
                    <div class="article-reguler" v-else-if="item.isText">
                      <!-- <Icons name="book" /> -->
                      <i class="bi bi-book-half"></i>
                      <p class="text">TEKS</p>
                    </div>
                    <p class="duration" v-else>
                      {{ item.timeVideo ? item.timeVideo : "00:00" }}
                    </p>
                  </div>
                </div>
                <div class="body">
                  <div class="channel">
                    {{ item.channel ? item.channel.title : "" }}
                  </div>
                  <div class="title">
                    {{ item.title }}
                  </div>
                </div>
              </Link>
            </div>
            <div class="swiper-pagination"></div>
          </div>

          <div
            class="shadow-arrow shadow-left prev-video-shihab"
            :class="{ hide: shihabShihab.items.length < 5 }"
          >
            <Icons name="arrow-left" class="arrow prev" color="#EFE9DD" />
          </div>
          <div
            class="shadow-arrow shadow-right next-video-shihab"
            :class="{ hide: shihabShihab.items.length < 5 }"
          >
            <Icons name="arrow-right" class="arrow next" color="#EFE9DD" />
          </div>
        </div>
      </div>
    </section>
    <section class="artikel-ramadan">
      <div class="container">
        <div class="title-wrapper">
          <TitleSection title="ARTIKEL RAMADAN" color="white" />
        </div>
        <div class="list-article">
          <div class="article-box">
            <div
              v-for="(item, index) in $store.state.articles
                .articles_tags_infinity.items"
              :key="`card-narasi-ramadan-${index}`"
              class="card-narasi-ramadan"
            >
              <Link
                :to="{
                  name: item.isText
                    ? 'read-channel-slug'
                    : 'video-channel-slug',
                  params: {
                    channel: item.channel.slug,
                    slug: item.slug,
                  },
                  query: {
                    ref: 'artikel-ramadan',
                  },
                }"
                class="card-narasi-ramadan-thumbnail"
              >
                <ImageResponsive
                  :imageUrl="
                    item.thumbnail && item.thumbnail.medium
                      ? item.thumbnail.medium
                      : 'https://stg.narasi.co.id/storage/images/dummy.png'
                  "
                  :imageUrlMobile="
                    item.thumbnail && item.thumbnail.medium
                      ? item.thumbnail.medium
                      : 'https://stg.narasi.co.id/storage/images/dummy.png'
                  "
                  :fromUrl="true"
                  width="100"
                  height="100"
                  class="card-narasi-ramadan-image"
                />
                <div
                  class="card-narasi-ramadan-duration"
                  :class="{ backgroundAds: item.isAdvertorial }"
                >
                  <p class="advertorial" v-if="item.isAdvertorial">Ad</p>
                  <div class="article-reguler" v-else-if="item.isText">
                    <!-- <Icons name="book" /> -->
                    <i class="bi bi-book-half"></i>
                    <p class="text">TEKS</p>
                  </div>
                  <p class="duration" v-else>
                    {{ item.timeVideo ? item.timeVideo : "00:00" }}
                  </p>
                </div>
              </Link>
              <div class="card-narasi-ramadan-body">
                <div class="card-narasi-ramadan-body-header">
                  <div class="card-narasi-ramadan-channel-category">
                    <Link
                      v-if="item && item.channel"
                      :to="{
                        name: 'program-childBrand-slugProgram',
                        params: {
                          childBrand: item.channel.program.slug,
                          slugProgram: item.channel.slug,
                        },
                        query: {
                          type: 'highlights',
                        },
                      }"
                      class="channel"
                      >{{ item.channel.title }}</Link
                    >
                  </div>
                  <Link
                    :to="{
                      name: item.isText
                        ? 'read-channel-slug'
                        : 'video-channel-slug',
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                      query: {
                        ref: 'artikel-ramadan',
                      },
                    }"
                    class="card-narasi-ramadan-title"
                  >
                    {{ item.title }}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="load-more">
          <div @click="loadMore()" class="lihat-semua">
            {{
              $store.state.articles.articles_tags_infinity.loading
                ? "Loading..."
                : "Muat Lebih Banyak"
            }}
          </div>
        </div>
        <!-- ADS PROPS START -->
        <div id="M857969ScriptRootC1408001" class="ads-props"></div>
        <!-- ADS PROPS END -->
      </div>
    </section>
  </div>
</template>

<script>
import config from "../../config";
import { mapGetters, mapState } from "vuex";
import CardTwentyFour from "/components/cards/CardTwentyFour.vue";
import NarasiPlayer from "narasi-player";
import "narasi-player/dist/narasi-player.css";
import ImageResponsive from "@/components/ImageResponsive";
import Icons from "@/components/Icons";
import Link from "@/components/Link";
import TitleSection from "@/components/TitleSection";
import ShimmerCardRamadan from "@/components/shimmer/CardRamadan";
import PesantrenKilat from "@/components/ramadan/PesantrenKilat.vue";
import ReligiHorizontalAds from "@/components/ads/religi/Horizontal.vue";

export default {
  async asyncData({ error, store, route, app, redirect }) {
    try {
      store.commit("seo/SET_SEO", {
        title: "Ramadan",
        desc: "Topik Seputar Ramadan yang menyajikan Jadwal Sholat, Jadwal Imsakiyah, Al-Quran Digital, Tausyiah, Kalkulator Zakat dan Doa-doa harian hanya di Narasi.",
        image: "https://narasi.tv/icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    ShimmerCardRamadan,
    TitleSection,
    Link,
    Icons,
    ImageResponsive,
    NarasiPlayer,
    CardTwentyFour,
    PesantrenKilat,
    ReligiHorizontalAds,
  },
  data() {
    return {
      page: 1,
      date: this.$moment(new Date()).local().format(),
      masks: {
        input: "WWWW, D MMMM YYYY",
      },
      kota: {
        kabkoKode: "58a2fc6ed39fd083f55d4182bf88826d",
        kabkoNama: "KOTA JAKARTA",
      },
      dateKode: this.$moment(new Date()).local().format("YYYY-MM-DD"),
      page: 1,
      swiperOptions: {
        videoKajian: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          observer: true,
          observeParents: true,
          navigation: {
            prevEl: ".prev-video-kajian",
            nextEl: ".next-video-kajian",
          },
          breakpoints: {
            1023: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 2.2,
              spaceBetween: 8,
            },
          },
        },
        videoShihab: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          observer: true,
          observeParents: true,
          navigation: {
            prevEl: ".prev-video-shihab",
            nextEl: ".next-video-shihab",
          },
          breakpoints: {
            1023: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 2.2,
              spaceBetween: 8,
            },
          },
        },
      },
    };
  },
  watch: {
    date(newValue, oldValue) {
      this.dateKode = newValue
        ? this.$moment(newValue).local().format("YYYY-MM-DD")
        : this.$moment(new Date()).local().format("YYYY-MM-DD");
      if (
        this.$moment(newValue).month() !== this.$moment(oldValue).month() ||
        this.$moment(newValue).year() !== this.$moment(oldValue).year()
      ) {
        this.getJadwal(true);
      } else {
        this.getJadwal(false);
      }
    },
    kota(newValue, oldValue) {
      if (
        newValue &&
        newValue.kabkoKode !== "58a2fc6ed39fd083f55d4182bf88826d"
      ) {
        window.location.href = this.$router.resolve({
          name: "religi-jadwal-sholat-slug",
          params: {
            slug: newValue.slug,
          },
        }).href;
      }
    },
  },
  head() {
    return {
      ...this.headReturn,
      script: [{ src: "https://jsc.mgid.com/p/r/props.narasi.tv.1408001.js" }],
    };
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      listKota: (state) => {
        return state.ramadan.kota.items;
      },
      listJadwal: (state) => {
        return state.ramadan.jadwal;
      },
      listJadwalBulanan: (state) => {
        return state.ramadan.jadwalBulanan.items;
      },
      shihabShihab: (state) => {
        return state.videos.tagsShihab;
      },
    }),
  },
  methods: {
    goToDate(param) {
      if (param === "minus") {
        this.date = this.$moment(this.date).subtract(1, "days").format();
      } else {
        this.date = this.$moment(this.date).add(1, "days").format();
      }
    },
    async getKota() {
      await this.$axios
        .$get(`${config.CORE_SERVICE_API}ramadhan`)
        .then((response) => {
          this.$store.commit("ramadan/SET_KOTA", response);
          return response;
        })
        .catch((e) => {
          return e.response;
        });
    },
    async getJadwal(firstLoad) {
      if (firstLoad) {
        await this.$axios
          .$post(`${config.CORE_SERVICE_API}ramadhan`, {
            year: this.$moment(this.dateKode).local().year().toString(),
            month: this.$moment(this.dateKode).local().month() + 1,
            prov: "c51ce410c124a10e0db5e4b97fc2af39",
            city: "58a2fc6ed39fd083f55d4182bf88826d",
          })
          .then((response) => {
            for (const key in response.data) {
              if (key == this.dateKode) {
                let data = {
                  imsak: response.data[key].imsak,
                  subuh: response.data[key].subuh,
                  dzuhur: response.data[key].dzuhur,
                  ashar: response.data[key].ashar,
                  maghrib: response.data[key].maghrib,
                  isya: response.data[key].isya,
                };
                this.$store.commit("ramadan/SET_JADWAL", {
                  dataSalat: data,
                  tanggal: response.data[key].tanggal,
                });
              }
            }
            this.$store.commit("ramadan/SET_JADWAL_BULANAN", response.data);
          })
          .catch((e) => {
            console.log(e.response);
          });
      } else {
        let data = {
          imsak: this.listJadwalBulanan[this.dateKode].imsak,
          subuh: this.listJadwalBulanan[this.dateKode].subuh,
          dzuhur: this.listJadwalBulanan[this.dateKode].dzuhur,
          ashar: this.listJadwalBulanan[this.dateKode].ashar,
          maghrib: this.listJadwalBulanan[this.dateKode].maghrib,
          isya: this.listJadwalBulanan[this.dateKode].isya,
        };
        this.$store.commit("ramadan/SET_JADWAL", {
          dataSalat: data,
          tanggal: this.listJadwalBulanan[this.dateKode].tanggal,
        });
      }
    },
    async getTagsShihab() {
      this.$store.commit("videos/SET_TAGS_SHIHAB", {
        loadingShowMore: true,
        tagsShihab: [],
      });
      await this.$axios
        .$get(`${config.CORE_SERVICE_API}tags/shihab-shihab?page=1&limit=10`)
        .then((response) => {
          this.$store.commit("videos/SET_TAGS_SHIHAB", {
            tagsShihab: response.data,
            pages: response.meta.pages,
            firstLoad: true,
          });
          return response;
        })
        .catch((e) => {
          return e.response;
        });
    },
    loadMore() {
      this.page++;
      this.$store.dispatch("articles/getArticlesByTagsInfinity", {
        tagsSlug: "ramadan-dan-religi",
        page: this.page,
        limit: 6,
      });
    },
  },
  mounted() {
    this.getKota();
    this.getJadwal(true);
    this.getTagsShihab();

    this.$store.dispatch("articles/getArticlesByTagsInfinity", {
      tagsSlug: "ramadan-dan-religi",
      page: this.page,
      limit: 6,
    });

    this.$store.dispatch("articles/getArticlesByTags", {
      tagsSlug: "pesantrenkilat,belajardarihati",
      page: 1,
    });
  },
};
</script>

<style lang="scss" scoped>
.ads-props {
  margin-top: 32px;
}
ins.home-ads[data-ad-status="unfilled"] {
  display: none !important;
}
ins.home-ads[data-ad-status="filled"] {
  margin-top: 3rem !important;
}
.v-select {
  ::v-deep {
    input {
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
    ul {
      li {
        &:hover {
          background: #8166c8;
          color: #ffe900;
        }
      }
    }
  }
}
.ramadan {
  .peskil {
    // background-color: #ffffff;
    @media only screen and (max-width: 1024px) {
      top: 124px;
    }
    ::v-deep .pesantren-kilat {
      // @media only screen and (max-width: 1024px) {
      //   padding-top: 0;
      // }
      .container {
        .content {
          border: 1px solid #c9c9c9;
          border-radius: 8px;
        }
      }
    }
  }
  .banner {
    position: relative;
    .banner-ramadan {
      width: 100%;
    }
    .headline {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: fit-content;
      @media only screen and (max-width: 1024px) {
        padding: 16px 0;
        top: 40%;
      }
      .container {
        .title-wrapper {
          background: rgba(245, 246, 250, 0.75);
          backdrop-filter: blur(2px);
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: fit-content;
          margin: 0 auto;
          padding: 12px 32px 12px 32px;
          border-radius: 8px;
          @media only screen and (max-width: 1024px) {
            text-align: center;
            gap: 0;
            width: 303px;
          }
          .title {
            font-weight: 800;
            font-size: 36px;
            font-family: "NarasiSans", "sans-serif";
            color: #1b5482;
            margin: 0;
            @media only screen and (max-width: 1024px) {
              font-size: 16px;
            }
          }
          .city {
            font-family: "NarasiSans", "sans-serif";
            font-size: 24px;
            font-weight: 500;
            color: #1b5482;
            @media only screen and (max-width: 1024px) {
              font-size: 16px;
              font-weight: 800;
            }
          }
          .sub-title {
            font-size: 12px;
            color: #1b5482;
            @media only screen and (max-width: 1024px) {
              font-size: 7px;
            }
          }
        }
      }
    }
  }
  .quran-dan-jadwal {
    position: relative;
    padding-bottom: 48px;
    margin-top: -7px;
    background: #1b5482;
    @media only screen and (max-width: 1024px) {
      margin-top: -50px;
    }
    .container {
      .schedule {
        display: flex;
        gap: 20px;
        @media only screen and (max-width: 1024px) {
          flex-direction: column-reverse;
          gap: 8px;
        }
        .quran {
          // display: none;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 22%;
          background: #f5f6fa;
          border-radius: 8px;
          overflow: hidden;
          .image {
            height: auto;
          }
          .title {
            display: flex;
            padding: 16px 16px 0;
            align-items: center;
            svg {
              margin-right: 10px;
            }
            p {
              font-size: 16px;
              font-weight: 500;
              margin: 0;
            }
          }
          .desc {
            padding: 16px 16px 0;
            font-size: 12px;
            color: #616161;
            margin-bottom: 30px;
          }
          .quran-btn {
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            @media only screen and (max-width: 1024px) {
              justify-content: space-between;
              padding: 8px 16px;
            }
            p {
              font-size: 14px;
              font-weight: 500;
              color: #1b5482;
              margin: 0;
            }
            svg {
              width: 24px;
              height: auto;
            }
          }
        }
        .pilih-jadwal {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 32%;
          background: #f5f6fa;
          border-radius: 8px;
          .image {
            height: auto;
          }
          .title {
            display: flex;
            padding: 16px 16px 0;
            align-items: center;
            svg {
              margin-right: 10px;
            }
            p {
              font-size: 16px;
              font-weight: 500;
              margin: 0;
            }
          }
          .desc {
            padding: 16px 16px 0;
            font-size: 12px;
            color: #616161;
            margin-bottom: 30px;
          }
          .quran-btn {
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            @media only screen and (max-width: 1024px) {
              justify-content: space-between;
              padding: 8px 16px;
            }
            p {
              font-size: 14px;
              font-weight: 500;
              color: #1b5482;
              margin: 0;
            }
            svg {
              width: 24px;
              height: auto;
            }
          }
          .pilih-kota {
            padding: 0 16px 16px;
          }
        }
        .jadwal-sholat {
          display: flex;
          flex: 45%;
          .jadwal {
            width: 100%;
            background: #f5f6fa;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 16px;
            @media only screen and (max-width: 1024px) {
              padding: 0;
              background: none;
            }
            .date {
              display: flex;
              justify-content: center;
              align-items: center;
              @media only screen and (max-width: 1024px) {
                background: #f5f6fa;
                border-radius: 8px;
                padding: 8px;
                margin-top: 16px;
              }
              .date-picker {
                position: relative;
                display: inline-block;
                cursor: pointer;
                .selected-date {
                  position: relative;
                  background: #ffffff;
                  padding: 6px 10px;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
                  .value-date {
                    font-size: 14px;
                    color: #202020;
                  }
                }
                .calendar-icon {
                  width: 15px;
                }
              }
              svg {
                width: 25px;
                height: auto;
                margin: 0 10px;
                cursor: pointer;
              }
            }
            .list-jadwal-sholat {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              gap: 20px;
              margin-top: 24px;
              @media only screen and (max-width: 1024px) {
                flex-direction: column;
                gap: 0;
                margin-top: 16px;
              }
              .shimmer-box-wrapper {
                flex-grow: 1;
                border-radius: 8px;
              }
              .box-wrapper {
                background: #ffffff;
                box-shadow: 0px 0px 4px 0px #00000033;
                border-radius: 8px;
                flex: 30%;
                @media only screen and (max-width: 1024px) {
                  padding: 8px;
                  flex: auto;
                }
                &:not(:first-child) {
                  @media only screen and (max-width: 1024px) {
                    border-radius: 0;
                  }
                }
                &:not(:last-child) {
                  @media only screen and (max-width: 1024px) {
                    border-radius: 0;
                  }
                }
                &:first-child {
                  @media only screen and (max-width: 1024px) {
                    border-radius: 8px 8px 0 0;
                  }
                }
                &:last-child {
                  @media only screen and (max-width: 1024px) {
                    border-radius: 0 0 8px 8px;
                  }
                }
                .box {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  padding: 16px;
                  @media only screen and (max-width: 1024px) {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 8px;
                  }
                  .waktu-sholat {
                    font-size: 18px;
                    font-weight: 500;
                    color: #202020;
                    margin: 0;
                    text-transform: capitalize;
                    @media only screen and (max-width: 1024px) {
                      font-size: 14px;
                    }
                  }
                  .jam-sholat {
                    font-size: 14px;
                    color: #202020;
                    margin: 0;
                    @media only screen and (max-width: 1024px) {
                      font-size: 14px;
                    }
                  }
                }
                &.active {
                  .box {
                    background: #1b5482cc;
                    border-radius: 8px;
                    .waktu-sholat {
                      color: #f5f6fa;
                    }
                    .jam-sholat {
                      color: #f5f6fa;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .campaign-ramadan {
    position: relative;
    padding: 48px 0;
    overflow: hidden;
    background: #4f8a95;
    .ornament-desktop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      pointer-events: none;
    }
    .ornament-mobile {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      pointer-events: none;
    }
    .container {
      position: relative;
      z-index: 2;
      .highlight-article {
        .image-title {
          width: 15vw;
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            width: 50vw;
            margin-bottom: 16px;
          }
        }
        .detail-article {
          display: flex;
          gap: 24px;
          @media only screen and (max-width: 1024px) {
            flex-direction: column;
          }
          .video-wrapper {
            border: 6px solid #f4bf5f;
            border-radius: 6px;
            flex: 50%;
            @media only screen and (max-width: 1024px) {
              flex: 100%;
            }
            ::v-deep iframe {
              @media only screen and (max-width: 1024px) {
                width: 100%;
                height: 51vw;
              }
            }
          }
          .copy-wrapper {
            flex: 50%;
            @media only screen and (max-width: 1024px) {
              flex: 100%;
            }
            .title {
              h1 {
                font-family: "Mangaba", Courier, monospace;
                font-size: 64px;
                color: #f4bf5f;
                @media only screen and (max-width: 1024px) {
                  font-size: 40px;
                }
              }
            }
            .desc {
              font-size: 16px;
              color: #cdc9bf;
              @media only screen and (max-width: 1024px) {
                font-size: 12px;
              }
            }
          }
        }
      }
      .kajian-ngabuburit {
        margin-top: 48px;
        @media only screen and (max-width: 1024px) {
          margin-top: 32px;
        }
        .title {
          border-color: #f4bf5f;
          color: #f4bf5f;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            font-size: 14px;
            margin-bottom: 6px;
          }
        }
        .list-article {
          position: relative;
          overflow: hidden;
          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            svg {
              pointer-events: visible;
            }

            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 0%, 0),
                rgba(79, 138, 149, 1)
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 0%, 0),
                rgba(79, 138, 149, 1)
              );
            }

            &.swiper-button-disabled {
              opacity: 0;
              z-index: 0;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            width: auto;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.prev {
              left: 0;
            }

            &.next {
              right: 0;
            }

            &.hide {
              display: none;
            }
          }

          .swiper-container {
            .swiper-wrapper {
              @media only screen and (max-width: 1024px) {
                height: fit-content !important;
                display: flex;
                flex-direction: column;
              }
            }
          }

          .card-kajian {
            width: 250px;
            height: fit-content;
            display: flex;
            flex-direction: column;
            @media only screen and (max-width: 1024px) {
              width: 100% !important;
              flex-direction: row;
              gap: 8px;
              padding: 12px 0;
            }
            &:not(:last-child) {
              @media only screen and (max-width: 1024px) {
                border-bottom: 1px solid #f4bf5f;
              }
            }
            .thumbnail {
              position: relative;
              border-radius: 10px;
              overflow: hidden;
              border: 4px solid #f4bf5f;
              @media only screen and (max-width: 1024px) {
                border-radius: unset;
                border: none;
                width: 25vw;
                height: 25vw;
                flex: inherit;
              }
              .image {
                width: 100%;
                height: auto;
              }
              .duration {
                background: rgba(0, 0, 0, 0.7);
                padding: 3px 6px;
                position: absolute;
                bottom: 0;
                right: 0;
                border-top-left-radius: 10px;
                @media only screen and (max-width: 1024px) {
                  border-top-left-radius: 6px;
                }
                &.backgroundAds {
                  background: rgba(128, 128, 128, 0.7);
                  padding: 3px 8px;
                }
                .advertorial {
                  font-size: 12px;
                  font-weight: 500;
                  color: #ffffff;
                  margin: 0;
                  @media only screen and (max-width: 1024px) {
                    font-size: 10px;
                  }
                }
                .article-reguler {
                  display: flex;
                  align-items: center;
                  .bi-book-half {
                    width: 16px;
                    height: auto;
                    margin-right: 2px;
                    color: #ffffff;
                  }
                  .text {
                    font-size: 10px;
                    font-weight: 500;
                    color: #ffffff;
                    margin: 0;
                  }
                }
                .duration {
                  font-size: 12px;
                  font-weight: 500;
                  color: #ffffff;
                  margin: 0;
                  @media only screen and (max-width: 1024px) {
                    font-size: 10px;
                  }
                }
              }
            }
            .body {
              padding: 16px 0;
              @media only screen and (max-width: 1024px) {
                padding: 0;
              }
              .channel {
                font-size: 12px;
                font-weight: 500;
                color: #ffe900;
                margin-bottom: 8px;
                @media only screen and (max-width: 1024px) {
                  font-size: 11px;
                  margin-bottom: 4px;
                }
              }
              .title {
                font-size: 16px;
                font-weight: 500;
                color: #fafafa;
                margin-bottom: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                @media only screen and (max-width: 1024px) {
                  font-size: 14px;
                  margin-bottom: 0;
                  -webkit-line-clamp: 4;
                }
              }
              .date {
                font-size: 12px;
                font-weight: 400;
                color: #bdbdbd;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .shihab-shihab {
    padding: 48px 0;
    background: #0095b8;
    .container {
      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        .lihat-semua {
          font-size: 16px;
          font-weight: 500;
          color: #ffe900;
          text-decoration: underline !important;
          &:hover {
            color: #ffe900 !important;
          }

          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }
      .list-article-shihab-shihab {
        position: relative;
        overflow: hidden;
        @media only screen and (max-width: 1024px) {
          overflow: visible;
        }
        .shadow-arrow {
          pointer-events: none;
          position: absolute;
          top: 0;
          width: 100px;
          height: 100%;
          padding: 0;
          z-index: 2;
          opacity: 1;
          transition: ease-in-out 0.5s;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          svg {
            pointer-events: visible;
          }

          &.shadow-left {
            left: 0;
            background: linear-gradient(to left, hsla(0, 0%, 0%, 0), #0095b8);
          }

          &.shadow-right {
            right: 0;
            background: linear-gradient(to right, hsla(0, 0%, 0%, 0), #0095b8);
          }

          &.swiper-button-disabled {
            opacity: 0;
            z-index: 0;
          }
        }

        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          width: auto;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          &.prev {
            left: 0;
          }

          &.next {
            right: 0;
          }

          &.hide {
            display: none;
          }
        }

        .swiper-container {
          @media only screen and (max-width: 1024px) {
            padding: 20px !important;
            margin: -20px !important;
          }
        }
        .swiper-wrapper {
          &.gap {
            gap: 16px;
            @media only screen and (max-width: 1024px) {
              gap: 8px;
            }
          }
        }

        .card-shihab {
          width: 250px;
          height: fit-content;
          display: flex;
          flex-direction: column;
          .thumbnail {
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            @media only screen and (max-width: 1024px) {
              border-radius: 4px;
            }
            .image {
              width: 100%;
              height: auto;
            }
            .duration {
              background: rgba(0, 0, 0, 0.7);
              padding: 3px 6px;
              position: absolute;
              bottom: 0;
              right: 0;
              border-top-left-radius: 10px;
              @media only screen and (max-width: 1024px) {
                border-top-left-radius: 6px;
              }
              &.backgroundAds {
                background: rgba(128, 128, 128, 0.7);
                padding: 3px 8px;
              }
              .advertorial {
                font-size: 12px;
                font-weight: 500;
                color: #ffffff;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  font-size: 10px;
                }
              }
              .article-reguler {
                display: flex;
                align-items: center;
                .bi-book-half {
                  width: 16px;
                  height: auto;
                  margin-right: 2px;
                  color: #ffffff;
                }
                .text {
                  font-size: 10px;
                  font-weight: 500;
                  color: #ffffff;
                  margin: 0;
                }
              }
              .duration {
                font-size: 12px;
                font-weight: 500;
                color: #ffffff;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  font-size: 10px;
                }
              }
            }
          }
          .body {
            padding: 16px 0;
            @media only screen and (max-width: 1024px) {
              padding: 12px 0 0;
            }
            .channel {
              font-size: 12px;
              font-weight: 500;
              color: #ffe900;
              margin-bottom: 8px;
              @media only screen and (max-width: 1024px) {
                font-size: 11px;
                margin-bottom: 4px;
              }
            }
            .title {
              font-size: 16px;
              font-weight: 500;
              color: #fafafa;
              margin-bottom: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              @media only screen and (max-width: 1024px) {
                font-size: 13px;
                -webkit-line-clamp: 4;
              }
            }
          }
        }
      }
    }
  }
  .artikel-ramadan {
    background: #1b5482;
    padding: 48px 0 80px;
    @media only screen and (max-width: 1024px) {
      padding: 32px 0 48px;
    }
    .container {
      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
      }
      .list-article {
        display: flex;
        gap: 20px;
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
        }
        .article-highlight {
          position: relative;
          flex: 30%;
          @media only screen and (max-width: 1024px) {
            height: 75vw;
            width: auto;
            flex: auto;
          }
          ::v-deep .highlight-image {
            img {
              object-fit: cover;
            }
          }
          .copy-wrapper {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: fit-content;
            background: rgba(0, 0, 0, 0.7);
            padding: 20px;
            @media only screen and (max-width: 1024px) {
              padding: 16px;
            }
            .highlight-channel {
              font-size: 16px;
              color: #f7e600;
              margin-bottom: 10px;
              display: inline-block;
              @media only screen and (max-width: 1024px) {
                font-size: 12px;
                margin-bottom: 8px;
              }
              &:hover {
                color: #f7e600 !important;
              }
            }
            .highlight-title {
              font-size: 24px;
              font-weight: 500;
              color: #f4f4f4;
              line-height: 32px;
              display: inline-block;
              @media only screen and (max-width: 1024px) {
                font-size: 16px;
                line-height: 24px;
              }
              &:hover {
                color: #f4f4f4 !important;
              }
            }
          }
        }
        .article-box {
          // flex: 70%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 20px;
          grid-row-gap: 10px;
          @media only screen and (max-width: 1024px) {
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 0;
          }
          &.fitContent {
            height: fit-content;
          }
          .card-narasi-ramadan {
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: row;
            flex: 30%;
            gap: 16px;
            padding-bottom: 10px;
            border-bottom: 1px solid #614f90;
            @media only screen and (max-width: 1024px) {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            &:nth-last-child(-n + 2) {
              border: none;
              padding: 0;
              @media only screen and (max-width: 1024px) {
                padding-bottom: 10px;
                border-bottom: 1px solid #614f90;
              }
            }
            &:last-child {
              @media only screen and (max-width: 1024px) {
                border: none;
                padding: 0;
              }
            }
            .card-narasi-ramadan-thumbnail {
              position: relative;
              // border-radius: 10px;
              overflow: hidden;
              flex: inherit;
              @media only screen and (max-width: 1024px) {
                // border-radius: 6px;
                width: 25vw;
                height: 25vw;
                flex: unset;
              }
              .card-narasi-ramadan-image {
                width: 100%;
                height: 100%;
                ::v-deep img {
                  object-fit: cover;
                }
              }
              .card-narasi-ramadan-duration {
                background: rgba(0, 0, 0, 0.7);
                padding: 3px 6px;
                position: absolute;
                bottom: 0;
                right: 0;
                border-top-left-radius: 10px;
                @media only screen and (max-width: 1024px) {
                  border-top-left-radius: 6px;
                }
                &.backgroundAds {
                  background: rgba(128, 128, 128, 0.7);
                  padding: 3px 8px;
                }
                .advertorial {
                  font-size: 12px;
                  font-weight: 500;
                  color: #ffffff;
                  margin: 0;
                  @media only screen and (max-width: 1024px) {
                    font-size: 10px;
                  }
                }
                .article-reguler {
                  display: flex;
                  align-items: center;
                  .bi-book-half {
                    width: 16px;
                    height: auto;
                    margin-right: 2px;
                    color: #ffffff;
                  }
                  .text {
                    font-size: 10px;
                    font-weight: 500;
                    color: #ffffff;
                    margin: 0;
                  }
                }
                .duration {
                  font-size: 12px;
                  font-weight: 500;
                  color: #ffffff;
                  margin: 0;
                  @media only screen and (max-width: 1024px) {
                    font-size: 10px;
                  }
                }
              }
            }
            .card-narasi-ramadan-body {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              flex: 75%;
              height: 110px;
              @media only screen and (max-width: 1024px) {
                height: 25vw;
                justify-content: unset;
                flex: inherit;
              }
              .card-narasi-ramadan-body-header {
                .card-narasi-ramadan-channel-category {
                  display: flex;
                  align-items: center;
                  margin-bottom: 8px;
                  .channel,
                  .category {
                    font-size: 12px;
                    font-weight: 400;
                    color: #ffe900;
                    margin: 0;
                    &:hover {
                      color: #ffe900 !important;
                    }
                    &.small {
                      @media only screen and (max-width: 1024px) {
                        font-size: 10px;
                      }
                    }
                  }
                  span {
                    font-size: 12px;
                    padding: 0 10px;
                    color: #ffe900;
                    @media only screen and (max-width: 1024px) {
                      font-size: 10px;
                    }
                    &.small {
                      @media only screen and (max-width: 1024px) {
                        font-size: 8px;
                        padding: 0 5px;
                      }
                    }
                  }
                }
                .card-narasi-ramadan-title {
                  font-size: 16px;
                  font-weight: 500;
                  color: #f1f1f1;
                  margin-bottom: 8px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 3;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  @media only screen and (max-width: 1024px) {
                    font-size: 14px;
                    line-height: 18px;
                    -webkit-line-clamp: 4;
                  }
                  &:hover {
                    color: #f1f1f1 !important ;
                  }
                }
              }
              .card-narasi-ramadan-body-footer {
                display: flex;
                flex-direction: row;
                align-items: center;
                .category {
                  display: none;
                  @media only screen and (max-width: 1024px) {
                    display: block;
                    font-size: 12px;
                    font-weight: 400;
                    color: #ffe900;
                    margin: 0;
                    padding-right: 5px;
                    &.small {
                      font-size: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .load-more {
        margin-top: 40px;
        .lihat-semua {
          margin: 0 auto;
          width: fit-content;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          color: rgba(27, 84, 130, 0.8);
          background-color: #f1f1f1;
          padding: 8px 16px;
          border-radius: 4px;
          &:hover {
            color: rgba(27, 84, 130, 0.8) !important;
          }
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
