<template>
  <div class="ramadan">
    <div class="peskil">
      <!-- <PesantrenKilat :articles="$store.state.articles.articles_tags.items" /> -->
    </div>
    <section class="banner">
      <img
        src="/storage/images/ramadan/bg-desktop.webp"
        class="banner-ramadan is-desktop"
      />
      <img
        src="/storage/images/ramadan/bg-mobile.png"
        class="banner-ramadan is-mobile"
      />
      <div class="headline">
        <div class="container">
          <div class="title-wrapper">
            <h1 class="title">
              Tafsir Surat {{ response.surah.surat_name }} ayat
              {{ this.$route.params.idAyat }}
            </h1>
            <span class="sub-title">
              SUMBER: LAJNAH PENTASHIHAN MUSHAF AL-QUR’AN, KEMENTERIAN AGAMA RI
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="ayat">
      <!-- START ADS -->
      <ReligiHorizontalAds />
      <!-- ADS END -->
      <div class="container">
        <div class="box-ayat">
          <h2>بِسْمِ اللّٰهِ الرَّحْمٰنِ الرَّحِيْمِ</h2>
          <div class="list-ayat">
            <div class="box-wrapper" v-if="response.data.length > 0">
              <div class="arabic-wrapper">
                <div class="nomor-ayat">
                  <Icons name="surat-icon" />
                  <p class="nomor">{{ this.$route.params.idAyat }}</p>
                </div>
                <p class="arabic">
                  {{ response.data[this.$route.params.idAyat - 1].arabic }}
                </p>
              </div>
              <p
                class="terjemahan"
                v-html="
                  response.data[this.$route.params.idAyat - 1].translation
                "
              ></p>
              <hr />
              <div class="tafsir tafsir-wajiz">
                <p
                  v-html="
                    response.data[this.$route.params.idAyat - 1].footnotes
                  "
                ></p>
              </div>
            </div>
          </div>
        </div>

        <div class="narasi-ramadan">
          <div class="container">
            <div class="title-wrapper">
              <TitleSection title="ARTIKEL RAMADAN" color="white" />
            </div>
            <div
              class="article-box"
              :class="{ fitContent: tags.items.length < 6 }"
            >
              <Link
                v-for="item in tags.items.slice(1, 7)"
                :key="item.id"
                :to="
                  item.isText
                    ? {
                        name: `read-channel-slug`,
                        params: {
                          channel: item.channel.slug,
                          slug: item.slug,
                        },
                        query: {
                          ref: 'artikel-narasi',
                        },
                      }
                    : {
                        name: 'video-channel-slug',
                        params: {
                          channel: item.channel.slug,
                          slug: item.slug,
                        },
                        query: {
                          ref: 'artikel-narasi',
                        },
                      }
                "
                class="card-narasi-ramadan"
              >
                <div class="card-narasi-ramadan-thumbnail">
                  <ImageResponsive
                    :imageUrl="
                      item.thumbnail && item.thumbnail.medium
                        ? item.thumbnail.medium
                        : 'https://stg.narasi.co.id/storage/images/dummy.png'
                    "
                    :imageUrlMobile="
                      item.thumbnail && item.thumbnail.medium
                        ? item.thumbnail.medium
                        : 'https://stg.narasi.co.id/storage/images/dummy.png'
                    "
                    :fromUrl="true"
                    width="100"
                    height="100"
                    class="card-narasi-ramadan-image"
                  />
                  <div
                    class="card-narasi-ramadan-duration"
                    :class="{ backgroundAds: item.isAdvertorial }"
                  >
                    <p class="advertorial" v-if="item.isAdvertorial">Ad</p>
                    <div class="article-reguler" v-else-if="item.isText">
                      <!-- <Icons name="book" /> -->
                      <i class="bi bi-book-half"></i>
                      <p class="text">TEKS</p>
                    </div>
                    <p class="duration" v-else>
                      {{ item.timeVideo ? item.timeVideo : "00:00" }}
                    </p>
                  </div>
                </div>
                <div class="card-narasi-ramadan-body">
                  <div class="card-narasi-ramadan-body-header">
                    <div class="card-narasi-ramadan-channel-category">
                      <Link
                        v-if="item && item.channel"
                        :to="{
                          name: 'program-childBrand-slugProgram',
                          params: {
                            childBrand: item.channel.program.slug,
                            slugProgram: item.channel.slug,
                          },
                          query: {
                            type: 'highlights',
                          },
                        }"
                        class="channel"
                        >{{ item.channel.title }}</Link
                      >
                    </div>
                    <div class="card-narasi-ramadan-title">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <Link
              :to="{
                name: 'religi-artikel',
              }"
              class="lihat-semua"
              >Lihat Semua
            </Link>
            <!-- ADS PROPS START -->
            <div id="M857969ScriptRootC1408001" class="ads-props"></div>
            <!-- ADS PROPS END -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import config from "../../../../config";
import { mapGetters, mapState } from "vuex";
import Icons from "@/components/Icons";
import Link from "@/components/Link";
import ImageResponsive from "@/components/ImageResponsive";
import TitleSection from "@/components/TitleSection";
import PesantrenKilat from "@/components/ramadan/PesantrenKilat.vue";
import ReligiHorizontalAds from "@/components/ads/religi/Horizontal.vue";

export default {
  async asyncData({ error, store, route, app, params, redirect }) {
    try {
      const response = await app.$axios.$get(
        `${config.CORE_SERVICE_API}ramadhan/surah/${params.slug}`
      );

      store.commit("seo/SET_SEO", {
        title: `Tafsir Surat ${response.surah.surat_name} Ayat ke ${params.idAyat}`,
        desc: `Tafsir Surat ${response.surah.surat_name} Ayat ke ${params.idAyat} Lengkap dengan isi kandungan surat dalam Bahasa Indonesia.`,
        image: "https://narasi.tv/icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });

      return { response };
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },

  components: {
    TitleSection,
    Icons,
    Link,
    ImageResponsive,
    PesantrenKilat,
    ReligiHorizontalAds,
  },
  data() {
    return { page: 1 };
  },
  head() {
    return {
      ...this.headReturn,
      script: [{ src: "https://jsc.mgid.com/p/r/props.narasi.tv.1408001.js" }],
    };
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      tafsir: (state) => {
        return state.ramadan.tafsir;
      },
      oneAyat: (state) => {
        return state.ramadan.oneAyat.items;
      },
      tags: (state) => {
        return state.videos.tags;
      },
    }),
  },
  async fetch() {
    await this.$store.dispatch("videos/getTags", {
      tagsSlug: "ramadan-dan-religi",
      page: this.page,
      firstLoad: true,
    });
  },
  mounted() {
    this.$store.dispatch("articles/getArticlesByTags", {
      tagsSlug: "pesantrenkilat,belajardarihati",
      page: 1,
    });
  },
};
</script>

<style lang="scss" scoped>
.ads-props {
  margin-top: 32px;
}
.ramadan {
  .peskil {
    background-color: #ffffff;
    @media only screen and (max-width: 1024px) {
      top: 124px;
    }
    ::v-deep .pesantren-kilat {
      @media only screen and (max-width: 1024px) {
        margin-top: 0;
      }
      .container {
        .content {
          border: 1px solid #c9c9c9;
          border-radius: 8px;
        }
      }
    }
  }
  .banner {
    position: relative;
    .banner-ramadan {
      width: 100%;
    }
    .headline {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: fit-content;
      @media only screen and (max-width: 1024px) {
        top: 87%;
        left: 50%;
      }
      .container {
        .title-wrapper {
          background: rgba(245, 246, 250, 1);
          backdrop-filter: blur(2px);
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: fit-content;
          margin: 0 auto;
          padding: 12px 32px 12px 32px;
          border-radius: 8px;
          @media only screen and (max-width: 1024px) {
            text-align: center;
            gap: 0;
            width: 303px;
          }
          .title {
            font-weight: 800;
            font-size: 36px;
            font-family: "NarasiSans", "sans-serif";
            color: #1b5482;
            margin: 0;
            @media only screen and (max-width: 1024px) {
              font-size: 16px;
            }
          }
          .city {
            font-family: "NarasiSans", "sans-serif";
            font-size: 24px;
            font-weight: 500;
            color: #1b5482;
            @media only screen and (max-width: 1024px) {
              font-size: 16px;
              font-weight: 800;
            }
          }
          .sub-title {
            font-size: 12px;
            color: #1b5482;
            @media only screen and (max-width: 1024px) {
              font-size: 7px;
            }
          }
        }
      }
    }
  }
  .ayat {
    background: #1b5482;
    padding-bottom: 48px;
    @media only screen and (max-width: 1024px) {
      padding-bottom: 32px;
      margin-top: -1px;
    }
    .container {
      display: flex;
      gap: 16px;
      padding-top: 20px;
      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        // @media only screen and (max-width: 1024px) {
        //   margin-top: -63px;
        // }
      }
      .box-ayat {
        flex: 65%;
        background: #ffffff;
        padding: 24px 22px;
        border-radius: 8px;
        @media only screen and (max-width: 1024px) {
          flex: 100%;
          z-index: 1;
        }
        h2 {
          text-align: center;
          margin-bottom: 24px;
          font-size: 28px;
          font-family: "Lpmq", "roboto";
          @media only screen and (max-width: 1024px) {
            font-size: 24px;
          }
        }
        .list-ayat {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(1, 1fr);
          grid-row-gap: 16px;
          grid-column-gap: 16px;
          @media only screen and (max-width: 1024px) {
            grid-template-columns: repeat(1, 1fr);
          }
          .box-wrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 24px;
            background: #ffffff;
            @media only screen and (max-width: 1024px) {
              gap: 8px;
              padding: 0;
            }

            .arabic-wrapper {
              display: flex;
              align-items: center;
              gap: 16px;
              align-self: flex-end;
              margin-bottom: 8px;
              .nomor-ayat {
                position: relative;
                @media only screen and (max-width: 1024px) {
                  display: none;
                }
                svg {
                  width: 30px;
                  height: auto;
                }
                .nomor {
                  font-size: 12px;
                  color: #1b5482;
                  margin: 0;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  @media only screen and (max-width: 1024px) {
                    font-size: 14px;
                  }
                }
              }
              .arabic {
                font-size: 28px;
                text-align: right;
                font-family: "Lpmq", "roboto";
                color: #202020;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  font-size: 24px;
                }
              }
            }
            .terjemahan {
              font-size: 16px;
              color: #202020;
              margin: 0;
              align-self: flex-start;
              @media only screen and (max-width: 1024px) {
                font-size: 14px;
              }
            }
            .tafsir {
              &.tafsir-wajiz {
                margin-bottom: 24px;
                @media only screen and (max-width: 1024px) {
                  margin-bottom: 16px;
                }
              }
              h2 {
                text-align: left;
                color: #051c2c;
                margin-bottom: 16px;
                @media only screen and (max-width: 1024px) {
                  font-size: 16px;
                }
              }
              p {
                font-size: 16px;
                color: #051c2c;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  font-size: 14px;
                }
              }
            }
            .dots {
              position: relative;
              align-self: flex-end;
              padding: 10px 0;
              svg {
                width: 20px;
                height: auto;
                display: block;
              }
              .popover-content {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: 40px;
                left: -70px;
                transform: translate(0, 10px);
                background-color: #ffffff;
                padding: 8px 16px;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
                width: auto;
                border-radius: 4px;
                &::before {
                  position: absolute;
                  z-index: -1;
                  content: "";
                  right: calc(14% - 10px);
                  top: -8px;
                  border: 1px solid #d9d9d9;
                  transition-duration: 0.3s;
                  transition-property: transform;
                }
                a {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  .book-icon {
                    width: 20px;
                    height: auto;
                  }
                  p {
                    font-size: 14px;
                    color: #202020;
                    margin: 0;
                  }
                }
              }
              &:hover {
                .popover-content {
                  z-index: 10;
                  opacity: 1;
                  visibility: visible;
                  transform: translate(0, -20px);
                  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
                }
              }
            }
          }
        }
      }

      .narasi-ramadan {
        flex: 30%;
        @media only screen and (max-width: 1024px) {
          margin-top: 24px;
          flex: 100%;
        }
        .container {
          display: flex;
          flex-direction: column;

          @media only screen and (max-width: 1024px) {
            padding: 0 0.75rem;
          }
          .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }

          .lihat-semua {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #ffe900;
            text-decoration: underline !important;
            &:hover {
              color: #ffe900 !important;
            }
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }

          .article-box {
            flex: 65%;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 0;
            grid-row-gap: 10px;
            @media only screen and (max-width: 1024px) {
              grid-template-columns: repeat(1, 1fr);
              grid-row-gap: 10px;
            }
            &.fitContent {
              height: fit-content;
            }
            .card-narasi-ramadan {
              width: 100%;
              height: fit-content;
              display: flex;
              flex-direction: row;
              flex: 30%;
              gap: 16px;
              padding-bottom: 10px;
              border-bottom: 1px solid #614f90;
              @media only screen and (max-width: 1024px) {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
              }
              &:last-child {
                border: none;
              }
              .card-narasi-ramadan-thumbnail {
                position: relative;
                // border-radius: 10px;
                overflow: hidden;
                flex: none;
                width: 5vw;
                height: 5vw;
                @media only screen and (max-width: 1024px) {
                  // border-radius: 6px;
                  width: 25vw;
                  height: 25vw;
                }
                .card-narasi-ramadan-image {
                  width: 100%;
                  height: 100%;
                  ::v-deep img {
                    object-fit: cover;
                  }
                }
                .card-narasi-ramadan-duration {
                  background: rgba(0, 0, 0, 0.7);
                  padding: 3px 6px;
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  border-top-left-radius: 10px;
                  @media only screen and (max-width: 1024px) {
                    border-top-left-radius: 6px;
                  }
                  &.backgroundAds {
                    background: rgba(128, 128, 128, 0.7);
                    padding: 3px 8px;
                  }
                  .advertorial {
                    font-size: 12px;
                    font-weight: 500;
                    color: #ffffff;
                    margin: 0;
                    @media only screen and (max-width: 1024px) {
                      font-size: 10px;
                    }
                  }
                  .article-reguler {
                    display: flex;
                    align-items: center;
                    .bi-book-half {
                      width: 16px;
                      height: auto;
                      margin-right: 2px;
                      color: #ffffff;
                    }
                    .text {
                      font-size: 10px;
                      font-weight: 500;
                      color: #ffffff;
                      margin: 0;
                    }
                  }
                  .duration {
                    font-size: 12px;
                    font-weight: 500;
                    color: #ffffff;
                    margin: 0;
                    @media only screen and (max-width: 1024px) {
                      font-size: 10px;
                    }
                  }
                }
              }
              .card-narasi-ramadan-body {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: auto;
                @media only screen and (max-width: 1024px) {
                  height: 25vw;
                  justify-content: unset;
                  flex: inherit;
                }
                .card-narasi-ramadan-body-header {
                  .card-narasi-ramadan-channel-category {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    .channel,
                    .category {
                      font-size: 12px;
                      font-weight: 400;
                      color: #ffe900;
                      margin: 0;
                      &:hover {
                        color: #ffe900 !important;
                      }
                      &.small {
                        @media only screen and (max-width: 1024px) {
                          font-size: 10px;
                        }
                      }
                    }
                    span {
                      font-size: 12px;
                      padding: 0 10px;
                      color: #ffe900;
                      @media only screen and (max-width: 1024px) {
                        font-size: 10px;
                      }
                      &.small {
                        @media only screen and (max-width: 1024px) {
                          font-size: 8px;
                          padding: 0 5px;
                        }
                      }
                    }
                  }
                  .card-narasi-ramadan-title {
                    font-size: 16px;
                    font-weight: 500;
                    color: #f1f1f1;
                    margin-bottom: 8px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    @media only screen and (max-width: 1024px) {
                      font-size: 14px;
                      line-height: 18px;
                      -webkit-line-clamp: 4;
                    }
                  }
                }
                .card-narasi-ramadan-body-footer {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  .category {
                    display: none;
                    @media only screen and (max-width: 1024px) {
                      display: block;
                      font-size: 12px;
                      font-weight: 400;
                      color: #ffe900;
                      margin: 0;
                      padding-right: 5px;
                      &.small {
                        font-size: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
